export var AudioErrorType;
(function (AudioErrorType) {
    AudioErrorType["FAILED_TO_INIT_AUDIO_CONTEXT"] = "FAILED_TO_INIT_AUDIO_CONTEXT";
    AudioErrorType["NO_MICROPHONE_SELECTED"] = "NO_MICROPHONE_SELECTED";
    AudioErrorType["NO_AUDIO_TRACKS"] = "NO_AUDIO_TRACKS";
    AudioErrorType["NO_WEBRTC_MANAGER"] = "NO_WEBRTC_MANAGER";
    AudioErrorType["PERMISSION_DENIED"] = "PERMISSION_DENIED";
    AudioErrorType["PERMISSION_DISMISSED"] = "PERMISSION_DISMISSED";
    AudioErrorType["NO_AUDIO_INPUTS"] = "NO_AUDIO_INPUTS";
    AudioErrorType["GENERIC"] = "GENERIC";
})(AudioErrorType || (AudioErrorType = {}));
const isPermissionDenied = (err) => {
    const chromePermissionDenied = err.message.includes('Permission denied');
    const firefoxSafariPermissionDenied = err.message.includes('not allowed by the user agent');
    return chromePermissionDenied || firefoxSafariPermissionDenied;
};
const isPermissionDismissed = (err) => {
    return err.message.includes('Permission dismissed');
};
export const errorToAudioError = (err) => {
    if (isPermissionDenied(err)) {
        return {
            audioErrorType: AudioErrorType.PERMISSION_DENIED,
        };
    }
    if (isPermissionDismissed(err)) {
        return {
            audioErrorType: AudioErrorType.PERMISSION_DISMISSED,
        };
    }
    return {
        audioErrorType: AudioErrorType.GENERIC,
        message: err.message,
    };
};
