import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import RoundedButton from '../../components/Buttons/RoundedButton';
import Loading from '../../components/Loading';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { selectDisplayDev } from '../../selectors/v1Session';
import { initiateFirebaseLoginWithToken } from '../../store/slices/auth';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getOS } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.ava.white,
    },
    textElectron: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '23.4px',
    },
    textWhiteElectron: {
        color: theme.palette.ava.white,
    },
    buttonElectron: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: 344,
        height: 56,
        paddingTop: 0,
        paddingRight: 16,
        paddingBottom: 0,
        paddingLeft: 20,
        [theme.breakpoints.down('lg')]: {
            maxWidth: 320,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 344,
        },
    },
    buttonsElectron: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1200,
        marginBottom: 30,
        '& > :not(:last-child)': {
            marginBottom: 12,
        },
        '&.Mui-disabled': {
            cursor: 'not-allowed !important',
        },
    },
    titleElectron: {
        marginBottom: 14,
        color: theme.palette.ava.blue1,
        fontSize: 40,
        fontWeight: 400,
        lineHeight: '52px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 34,
        },
    },
    customTokenInput: {
        width: '75%',
        '& textarea': { fontFamily: 'monospace', color: theme.palette.ava.grey },
    },
    loginTokenButton: {
        color: theme.palette.ava.blue2,
        background: 'transparent',
        border: 'none',
        outline: 'none',
    },
    imageElectron: {
        marginTop: -40,
        height: 'calc(30vw - 10px)',
        maxHeight: 540,
        minHeight: 380,
        width: '100vw',
        background: theme.palette.mode === 'dark' ? `url(/work_dark.svg)` : `url(/work.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
}));
function ElectronLandingPage() {
    const classes = useStyles();
    const [customTokenInputShown, setCustomTokenInputShown] = useState(false);
    const [customToken, setCustomToken] = useState('');
    const displayDev = useAppSelector(selectDisplayDev);
    const dispatch = useAppDispatch();
    // dont render this page if not in electron
    if (!window.isElectron) {
        return React.createElement(Loading, null);
    }
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.imageElectron }),
        React.createElement(Typography, { variant: "h4", align: "center", className: classes.titleElectron },
            React.createElement(PhraseMarkdownText, { phraseKey: "landing.welcomeToAva" })),
        React.createElement("div", { className: classes.buttonsElectron },
            !customTokenInputShown && (React.createElement(RoundedButton, { className: classNames(classes.buttonElectron, classes.textElectron, classes.textWhiteElectron), style: { justifyContent: 'center' }, color: "blue", onClick: () => {
                    if (window.desktopIntegration) {
                        // because of how our login flows work, we need to open the browser
                        // with the login url  with the query params.
                        const url = new URL(window.location.href.replace('/desktop', ''));
                        url.searchParams.append('auth', 'desktop');
                        if (window.__TAURI__) {
                            url.searchParams.append('tauri', 'true');
                        }
                        window.desktopIntegration.openExternalURL(url.href);
                    }
                } },
                React.createElement(PhraseMarkdownText, { phraseKey: "desktopLogin.signInWithBrowser" }))),
            ((window.isElectron && getOS() === 'Linux') || displayDev) &&
                (!customTokenInputShown ? (React.createElement("button", { className: classes.loginTokenButton, onClick: () => setCustomTokenInputShown(true) }, "I have login token")) : (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { variant: "outlined", className: classes.customTokenInput, multiline: true, placeholder: "Paste your login token here", onChange: (event) => setCustomToken(event.target.value) }),
                    React.createElement(RoundedButton, { className: classNames(classes.buttonElectron, classes.textElectron, classes.textWhiteElectron), style: { justifyContent: 'center' }, color: "blue", disabled: customToken === '', onClick: () => initiateFirebaseLoginWithToken(dispatch, customToken) }, "Log in with token")))))));
}
export default ElectronLandingPage;
