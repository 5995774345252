import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { selectCanRecord, selectShowKeyboardInput } from '../../selectors/combined';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectIsScribing } from '../../selectors/scribe';
import { selectScribeTrainingRequested } from '../../selectors/scribe-dashboard';
import { selectFullScreen } from '../../selectors/ui';
import { selectAvaId } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { isMobile } from '../../utils';
import { isConversationEnded, isDefaultRoomId } from '../../utils/ws-v1';
import AudioRecorder from '../AudioRecorder';
import { AvaTranslateButton } from './AvaTranslateButton';
import CCButton from './CCButton';
import CloseButton from './CloseButton';
import KeyboardButton from './KeyboardButton';
import TrainingButton from './TrainingButton';
const useStyles = makeStyles({
    container: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 101,
        display: 'flex',
        flexDirection: 'column',
        width: 70,
        marginTop: 20,
        '& > :not(:last-child)': {
            marginBottom: 20,
        },
    },
});
const ConversationButtons = ({ onKeyboardButtonClick, onEndConversationClick, status }) => {
    const classes = useStyles();
    const scribeTrainingRequested = useSelector(selectScribeTrainingRequested);
    const fullScreen = useAppSelector(selectFullScreen);
    const avaId = useAppSelector(selectAvaId);
    const scribing = useAppSelector(selectIsScribing);
    const isInConversation = useAppSelector(selectIsInConversation);
    const showKeyboardButton = useAppSelector(selectShowKeyboardInput);
    const showMicrophoneButton = useAppSelector(selectCanRecord);
    const showCCButton = (!status.participants || isInConversation) &&
        status.participants &&
        !fullScreen &&
        !scribing &&
        !isDefaultRoomId(status.id) &&
        !isMobile() &&
        !window.isElectron;
    const isCCButtonDesktop = status.participants && !scribing && !isDefaultRoomId(status.id) && window.isElectron;
    const showCloseButton = status.participants && (!scribing || scribeTrainingRequested) && !fullScreen && !isDefaultRoomId(status.id);
    const showTrainingButton = scribing && scribeTrainingRequested;
    if (!isInConversation)
        return null;
    return (React.createElement("div", { className: classes.container },
        showMicrophoneButton && (React.createElement(AudioRecorder, { hideButton: scribeTrainingRequested, conversationEnded: isConversationEnded(status, avaId) })),
        React.createElement(AvaTranslateButton, null),
        showKeyboardButton && React.createElement(KeyboardButton, { onClick: onKeyboardButtonClick }),
        showTrainingButton && React.createElement(TrainingButton, null),
        showCCButton && React.createElement(CCButton, null),
        isCCButtonDesktop && React.createElement(CCButton, { isDesktop: true }),
        showCloseButton && React.createElement(CloseButton, { onEndConversationClick: onEndConversationClick })));
};
const mapStateToProps = ({ scribeConversation: { status } }) => ({
    status,
});
export default connect(mapStateToProps)(ConversationButtons);
