import { Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage, langs, langsDisplay } from '../../utils/i18n';
const useStyles = makeStyles((theme) => ({
    button: {
        alignSelf: 'flex-end',
        borderRadius: 100,
        background: 'transparent',
        boxShadow: '0px 4px 4px rgba(112, 144, 176, 0.25)',
        padding: '14px 24px 10px 24px',
        width: '105px',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
        },
        // iphone SE
        ['@media (max-width: 375px)']: { fontSize: 12, width: 'auto' },
        color: theme.palette.ava.deepBlue,
        fontWeight: 600,
        fontSize: 18,
    },
}));
const LanguagePicker = () => {
    const [open, setOpen] = useState(false);
    const parentRef = useRef(null);
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguage());
    const updateLanguage = (language) => {
        setSelectedLanguage(langsDisplay[language]);
        i18n.changeLanguage(language, () => {
            window.document.title = t('title');
        });
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { ref: parentRef, id: "language-button", "aria-controls": "language-menu", "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, disableRipple: true, onClick: () => setOpen(true), className: classes.button }, selectedLanguage),
        React.createElement(Menu, { anchorOrigin: { horizontal: 'center', vertical: 'top' }, transformOrigin: { horizontal: 'center', vertical: 'center' }, anchorEl: parentRef.current, id: "simple-menu", open: open }, langs.map((lang) => (React.createElement(MenuItem, { color: "primary", key: lang, onClick: () => updateLanguage(lang), selected: i18n.language === lang }, langsDisplay[lang]))))));
};
export default LanguagePicker;
