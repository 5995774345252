import i18next from 'i18next';
import { AVA_URL } from '../constants';
export const langs = ['fr', 'en'];
export const langsDisplay = {
    fr: 'Français',
    en: 'English',
};
export const getLanguageCode = () => i18next.language.split('-')[0];
export const getAvaLanguageUrl = () => {
    const languageCode = getLanguageCode();
    if (languageCode !== 'en') {
        return `${AVA_URL}/${languageCode}`;
    }
    return AVA_URL;
};
export const getCurrentLanguage = () => {
    return langsDisplay[getLanguageCode()];
};
export const getPhraseOrEmptyString = (key) => {
    return i18next.exists(key) ? i18next.t(key) : '';
};
export const getPhraseBoldData = (phrase, separator) => {
    const phrases = phrase.split(separator);
    return phrases.map((phrase, index) => ({ text: phrase, isBold: index % 2 === 1 })).filter((p) => p.text);
};
