var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ArrowRight from '../../../assets/images/arrow-right.png';
import { setLangSelected } from '../../actions';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectAudioV2Status } from '../../selectors/audioV2';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectScribeTrainingFileUrl, selectScribeTrainingLang } from '../../selectors/scribe-dashboard';
import { RecordingStatus, startRecording } from '../../store/slices/audioV2';
import { updateSpeakerListened } from '../../store/slices/scribe';
import { scribeDashboardStreamReady } from '../../store/slices/scribeDashboard';
import { useAppDispatch } from '../../store/store';
const TrainingButton = () => {
    const scribeTrainingFileURL = useSelector(selectScribeTrainingFileUrl);
    const scribeTrainingLang = useSelector(selectScribeTrainingLang);
    const isRecording = useSelector(selectAudioV2Status) == RecordingStatus.RECORDING;
    const status = useSelector(selectStatus);
    const audioRef = useRef(null);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [socket] = useV1Socket();
    const [clicked, setClicked] = useState(false);
    const onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        yield audioRef.current.play();
        const ctx = new AudioContext();
        const source = ctx.createMediaElementSource(audioRef.current);
        const destination = ctx.createMediaStreamDestination();
        source.connect(destination);
        dispatch(scribeDashboardStreamReady({ audioStream: destination.stream }));
        dispatch(startRecording());
        setClicked(true);
    });
    useEffect(() => {
        var _a;
        if (!status || !((_a = status.audioStreams) === null || _a === void 0 ? void 0 : _a.length))
            return;
        dispatch(updateSpeakerListened(status.audioStreams[0]));
        dispatch(setLangSelected(scribeTrainingLang));
    }, [status.audioStreams.length]);
    return (React.createElement("div", { style: { display: clicked ? 'none' : 'inherit' } },
        React.createElement("audio", { crossOrigin: "anonymous", controls: true, ref: audioRef, preload: "true", style: { display: 'none' } },
            React.createElement("source", { src: scribeTrainingFileURL, type: "audio/webm" })),
        React.createElement(Fab, { color: "secondary", style: { backgroundColor: theme.palette.ava.green }, onClick: onClick },
            React.createElement("img", { src: ArrowRight, alt: "begin scribe training", height: "20" }))));
};
export default TrainingButton;
