import { createSelector } from 'reselect';
import { RecordingStatus } from '../store/slices/audioV2';
import { isFirefox, isLinux, isMac, isWindows } from '../utils';
import { getScribes, hasScribe } from '../utils/status';
import { selectAudioV2Status, selectMicrophoneAccess, selectNeedInternalAudioAccess } from './audioV2';
import { selectFirebaseAuthInitiated, selectFirebaseAuthTriggered, selectFirebaseUser } from './auth';
import { selectAutoTranslateLanguage } from './avaTranslate';
import { selectInTwilioCalls, selectIsInConversation } from './conversation';
import { selectFurthestObservedCursors, selectLang, selectStatus, selectUniqueParticipants, } from './legacy-conversation';
import { selectIsHost } from './legacy-conversation';
import { selectShowQuickSetup } from './quickSetup';
import { selectRecallAIStatus } from './recallAI';
import { selectIsScribing } from './scribe';
import { selectScribeTrainingRequested } from './scribe-dashboard';
import { selectElectronCaptionMode, selectFullScreen } from './ui';
import { selectAvaId, selectUserProfileFetchFinished, selectUserProfileFetchInitiated } from './userProfile';
import { selectConferenceCallRequested } from './v1Session';
export const selectConversationMuted = createSelector([selectStatus, selectIsScribing, selectAvaId], (status, scribing, currentAvaId) => {
    return (status.conversationMode === 'public-muted' &&
        !scribing &&
        (!currentAvaId || (status.host && status.host.avaId !== currentAvaId)));
});
export const selectFirebaseLoginInProgress = createSelector([selectFirebaseAuthInitiated, selectUserProfileFetchInitiated], (x, y) => {
    return x || y;
});
export const selectLoggedIn = createSelector([selectFirebaseUser, selectAvaId], (firebaseUser, avaId) => {
    return !!avaId && !!firebaseUser && !firebaseUser.isAnonymous;
});
export const selectLoggedInAnonymously = createSelector([selectFirebaseUser], (firebaseUser) => {
    return firebaseUser && firebaseUser.isAnonymous;
});
export const selectLoggedOut = createSelector([
    selectFirebaseAuthTriggered,
    selectFirebaseAuthInitiated,
    selectFirebaseUser,
    selectAvaId,
    selectUserProfileFetchFinished,
], (firebaseAuthTriggered, firebaseAuthInitiated, firebaseUser, avaId, userProfileFetchFinished) => {
    if (!firebaseAuthTriggered) {
        // Firebase Auth has not been triggered yet - we know nothing about our
        // authentication state. We cannot say whether we are logged in or logged
        // out.
        return false;
    }
    if (firebaseAuthInitiated) {
        // Login has been initiated, but not completed
        return false;
    }
    if (firebaseUser && !userProfileFetchFinished) {
        // We have a firebase user, but we have not yet finished the call to
        // get user profile.
        return false;
    }
    return !firebaseUser || !avaId;
});
export const selectFurthestObservedByScribe = createSelector([selectStatus, selectFurthestObservedCursors], (status, furthestObservedCursors) => {
    let furthestObservedByScribe;
    if (hasScribe(status)) {
        // Finding the furthest word any scribe has clicked on
        const scribeCursors = getScribes(status).map((scribe) => furthestObservedCursors[scribe.hash]);
        for (const cursor of scribeCursors) {
            if (!cursor)
                continue;
            if (!furthestObservedByScribe ||
                furthestObservedByScribe.tid < cursor.tid ||
                (furthestObservedByScribe.tid === cursor.tid && furthestObservedByScribe.index < cursor.index)) {
                furthestObservedByScribe = cursor;
            }
        }
    }
    return furthestObservedByScribe;
});
// The language for transcripts to show the user. This will either be the `lang`
// kept in scribeConversation, or a language decided by the auto translation
// feature. This can be xx, xx-XX, or one of the weird google language codes.
// It's difficult to keep up with various language codes, so be careful.
export const selectLangForTranscripts = createSelector([selectLang, selectAutoTranslateLanguage], (lang, autoTranslateLang) => {
    return autoTranslateLang || lang;
});
export const selectShowKeyboardInput = createSelector([
    selectConversationMuted,
    selectRecallAIStatus,
    selectIsScribing,
    selectFullScreen,
    selectElectronCaptionMode,
    selectConferenceCallRequested,
    selectInTwilioCalls,
    selectIsInConversation,
    selectIsHost,
], (conversationMuted, recallAIStatus, isScribing, fullScreen, electronCaptionMode, conferenceCallRequested, inTwilioCalls, isInConversation, isHost) => (!conversationMuted || (recallAIStatus === 'CAPTIONING' && isHost)) &&
    !isScribing &&
    (!fullScreen || electronCaptionMode) &&
    !conferenceCallRequested &&
    !inTwilioCalls &&
    isInConversation);
export const selectQuickSetupShown = createSelector([selectNeedInternalAudioAccess, selectMicrophoneAccess, selectShowQuickSetup], (needInternalAudioAccess, microphoneAccess, showQuickSetup) => needInternalAudioAccess || microphoneAccess !== 'granted' || showQuickSetup);
export const selectCanRecord = createSelector([
    selectRecallAIStatus,
    selectConversationMuted,
    selectIsInConversation,
    selectIsScribing,
    selectScribeTrainingRequested,
], (recallAIStatus, conversationMuted, isInConversation, isScribing, scribeTrainingRequested) => {
    return (!conversationMuted &&
        isInConversation &&
        !(recallAIStatus === 'CAPTIONING' || recallAIStatus === 'DISCONNECT') &&
        (!isScribing || scribeTrainingRequested));
});
export const selectCanBeginRecording = createSelector([selectAudioV2Status, selectCanRecord], (audioStatus, canRecord) => {
    return canRecord && audioStatus === RecordingStatus.NOT_RECORDING;
});
export const selectIsAvaMicAvailable = createSelector([selectUniqueParticipants, selectConversationMuted], (participants, conversationMuted) => {
    // TODO: why is conversation muted here?
    if (participants.filter((participant) => participant && !participant.scribe).length > 1 && !conversationMuted) {
        return false;
    }
    else if (isMac) {
        // window.isElectron sometimes returns nontruthy value when should be truthy because javascript
        return window.isElectron !== undefined;
    }
    else if (isWindows) {
        // TODO: Verify that maybe Firefox on Windows now works?
        // Capturing system-wide audio is currently only available on Windows, but not in
        // Firefox (see this issue: https://bugzilla.mozilla.org/show_bug.cgi?id=1541425).
        return !isFirefox && !!navigator.mediaDevices.getDisplayMedia;
    }
    else if (isLinux) {
        return false;
    }
    else
        return true;
});
