var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../hooks/useNotifications';
import { selectBoostWords } from '../../selectors/boost';
import { addBoostWordsRequest, resetBoostWordsStatus } from '../../store/slices/boost';
import { useAppDispatch, useAppSelector } from '../../store/store';
import ValidatedMessage from '../ValidatedMessage';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    scrollPaper: {
        background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.1)',
    },
    dialogPaper: {
        justifyContent: 'space-between',
        padding: 40,
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark2,
        borderRadius: 20,
        maxHeight: 560,
        maxWidth: 540,
        [theme.breakpoints.down('md')]: {
            maxHeight: '94%',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 24,
            margin: 0,
        },
    },
    heading: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '26px',
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
            lineHeight: '18px',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    button: {
        borderRadius: 100,
        fontSize: 20,
        lineHeight: '26px',
        fontWeight: 400,
        padding: '15px 20px',
        boxShadow: '0px 4px 4px rgba(112, 144, 176, 0.25)',
        position: 'relative',
    },
    buttonMain: {
        width: 'calc(50% - 12px)',
    },
    buttonBorder: {
        border: `1px solid ${theme.palette.ava.blue2}`,
        color: theme.palette.ava.blue2,
        background: theme.palette.ava.white,
        '&:hover': {
            background: theme.palette.ava.navy5,
        },
    },
    buttonFill: {
        color: theme.palette.ava.white,
        background: theme.palette.ava.blue2,
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
        },
    },
    divider: {
        backgroundColor: theme.palette.ava.grey6,
        marginTop: 12,
        marginBottom: 16,
    },
    wordsScroll: {
        marginRight: -16,
        paddingRight: 16,
        overflow: 'auto',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: 6,
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 12,
            background: theme.palette.ava.grey6,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.ava.grey5,
        },
    },
    wordContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 18,
        lineHeight: 1.3,
        height: 42,
    },
    addButton: {
        padding: '7px 20px 7px 15px',
    },
    addButtonText: {
        marginLeft: 7,
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));
const BoostPopup = ({ open, onClose, editedWords }) => {
    const classes = useStyles();
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const [words, setWords] = useState(editedWords.map((w) => ({ text: w })));
    const [addAllClicked, setAddAllClicked] = useState(false);
    const [allTimeout, setAllTimeout] = useState();
    const boostWords = useAppSelector(selectBoostWords);
    const dispatch = useAppDispatch();
    useEffect(() => {
        words.forEach((word) => {
            if (!word.success && boostWords.map((w) => w.toLowerCase()).includes(word.text.toLowerCase())) {
                word.loading = false;
                word.success = true;
                if (!addAllClicked) {
                    word.timeout = setTimeout(() => {
                        setWords((words) => words.filter((w) => w.text !== word.text));
                    }, 3000);
                }
            }
        });
        if (words.every((w) => w.success) && addAllClicked) {
            words.forEach((word) => clearTimeout(word.timeout));
            setAllTimeout(setTimeout(() => {
                setWords([]);
            }, 1000));
        }
        setWords([...words]);
    }, [boostWords]);
    useEffect(() => {
        if (!words || !words.length) {
            if (allTimeout) {
                clearTimeout(allTimeout);
            }
            onClose();
        }
    }, [words]);
    useEffect(() => {
        return () => {
            if (words && words.length) {
                words.forEach((word) => word.timeout && clearTimeout(word.timeout));
            }
            dispatch(resetBoostWordsStatus());
        };
    }, []);
    const handleAddBoostWords = (boostWords) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(addBoostWordsRequest(boostWords)).unwrap();
        }
        catch (_) {
            notify(t('boost.error.requestFailure'), { variant: 'error' });
        }
    });
    const onAddWord = (word) => {
        word.loading = true;
        setWords([...words]);
        handleAddBoostWords([word.text]);
    };
    const onAddAll = () => {
        setAddAllClicked(true);
        const newWords = words.filter((w) => !w.loading && !w.success).map((w) => w.text);
        handleAddBoostWords(newWords);
    };
    const successMessage = t('options.added');
    return (React.createElement(Dialog, { className: classes.root, classes: { scrollPaper: classes.scrollPaper, paper: classes.dialogPaper }, open: open, onClose: onClose },
        React.createElement("h4", { className: classes.heading }, t('boost.dialog.heading', { count: words.length })),
        React.createElement("div", { className: classes.wordsScroll }, words.map((word) => {
            let controls;
            if (word.loading) {
                controls = React.createElement(CircularProgress, { size: 16, color: "primary", className: classes.wordLoading });
            }
            else if (word.success) {
                controls = React.createElement(ValidatedMessage, { message: successMessage });
            }
            else {
                controls = (React.createElement(Button, { className: classnames(classes.button, classes.buttonBorder, classes.addButton), onClick: () => onAddWord(word) },
                    React.createElement(AddIcon, null),
                    React.createElement("span", { className: classes.addButtonText }, t('boost.dialog.add'))));
            }
            return (React.createElement(React.Fragment, { key: word.text },
                React.createElement("div", { className: classes.wordContainer },
                    React.createElement("span", null, word.text),
                    controls),
                React.createElement(Divider, { className: classes.divider })));
        })),
        React.createElement("div", { className: classes.buttonContainer },
            React.createElement(Button, { className: classnames(classes.button, classes.buttonMain, classes.buttonBorder), onClick: onClose }, t('boost.dialog.ignore')),
            React.createElement(Button, { className: classnames(classes.button, classes.buttonMain, classes.buttonFill), onClick: onAddAll, disabled: addAllClicked },
                t('boost.dialog.addAll'),
                addAllClicked && React.createElement(CircularProgress, { size: 24, color: "secondary", className: classes.loading })))));
};
export default BoostPopup;
