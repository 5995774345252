import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarButton from '../../components/Buttons/SidebarButton';
import { selectIsSideBarOpen } from '../../selectors/ui';
import { toggleSideBar } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { SNCF } from '../../utils';
import RoundedButton from '../Buttons/RoundedButton';
import AvaMagnifyingLogoIcon from '../Icons/AvaMagnifying';
import NewJoinConversation from './NewJoinConversation';
const useStyles = makeStyles((theme) => ({
    landing: {
        width: '100%',
        marginLeft: 0,
        height: 54,
    },
    button: {
        position: 'relative',
        width: (props) => (props.frenchHeader ? 286 : 256),
        height: 40,
        paddingTop: 0,
        paddingRight: 20,
        paddingBottom: 0,
        paddingLeft: 20,
    },
    label: {
        display: 'flex',
    },
    icon: {
        position: 'absolute',
        top: 6,
        left: 20,
    },
    search: {
        flex: 1,
    },
    cursor: {
        marginLeft: 20,
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: ({ sidebarOpen }) => (sidebarOpen ? 28 : 'none'),
    },
    focused: {
        border: `1px solid ${theme.palette.ava.blue2}`,
        boxShadow: '0px 0px 0px 4px rgba(3, 132, 198, 0.12)',
    },
}));
const JoinConversationButton = ({ header, landing }) => {
    const { i18n: { language }, } = useTranslation();
    const sidebarOpen = useAppSelector(selectIsSideBarOpen);
    const [buttonClicked, setButtonClicked] = useState(false);
    const classes = useStyles({ sidebarOpen, frenchHeader: language === 'fr' && header });
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!sidebarOpen) {
            setButtonClicked(false);
        }
    }, [sidebarOpen]);
    const onClick = () => {
        dispatch(toggleSideBar(true));
        setButtonClicked(true);
    };
    if (SNCF)
        return null;
    return (React.createElement(ClickAwayListener, { onClickAway: () => setButtonClicked(false) },
        React.createElement(Box, null, header ? (React.createElement(RoundedButton, { className: classnames(classes.button, {
                [classes.focused]: buttonClicked,
            }), buttonClasses: [classes.label], color: "white", onClick: () => setButtonClicked(true), disableRipple: true },
            header ? (React.createElement(SearchIcon, { className: classes.icon })) : (React.createElement("div", { className: classes.icon },
                React.createElement(AvaMagnifyingLogoIcon, { width: 24, height: 24 }))),
            React.createElement("div", { className: classnames(classes.search, { [classes.cursor]: buttonClicked }) },
                React.createElement(NewJoinConversation, { autoFocus: buttonClicked, header: header })))) : (React.createElement(SidebarButton, { sidebarOpen: sidebarOpen || !!landing, className: classnames({ [classes.focused]: buttonClicked, [classes.landing]: landing }), color: "white", onClick: onClick, icon: React.createElement("div", { className: classes.searchContainer, style: { marginLeft: sidebarOpen ? 3 : -2 } },
                React.createElement(AvaMagnifyingLogoIcon, { width: 28, height: 28 })) },
            React.createElement(NewJoinConversation, { landing: landing, autoFocus: buttonClicked }))))));
};
export default JoinConversationButton;
