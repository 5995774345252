import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    name: '',
};
export const onboardingV2Slice = createSlice({
    name: 'onboardingV2',
    initialState,
    reducers: {
        setName(state, { payload }) {
            state.name = payload;
        },
    },
});
export const onboardingV2Reducer = onboardingV2Slice.reducer;
export const { setName } = onboardingV2Slice.actions;
