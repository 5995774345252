import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import WebsocketReconnectingBanner from '../../../components/Network/WebsocketReconnectingBanner';
import BoostPopup from '../../../components/Popups/BoostPopup';
import SaveTranscriptPopup from '../../../components/Popups/SaveTranscriptPopup';
import { useNotifications } from '../../../hooks/useNotifications';
import ScribeTranscriptListContainer from '../../../modules/scribe/ScribeTranscriptListContainer';
import ScribeVolumeController from '../../../modules/scribe/ScribeVolumeController';
import SoloDiaSpeakerNameModal, { SoloDiaNameModalProvider } from '../../../modules/scribe/SoloDiaSpeakerNameModal';
import { selectConversationMuted } from '../../../selectors/combined';
import { selectConversationEnded, selectIsInConversation, selectJoiningStatus } from '../../../selectors/conversation';
import { selectStatus } from '../../../selectors/legacy-conversation';
import { selectIsScribing } from '../../../selectors/scribe';
import { selectFullScreen, selectInsertSpeakerNameMode } from '../../../selectors/ui';
import { selectAvaId } from '../../../selectors/userProfile';
import { setJoiningStatus } from '../../../store/slices/conversation';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import useStyles from './ConversationView.styles';
const ConversationView = ({ conversationContainerHovered, editedWords, handleBoostPopup, handleSaveTranscriptPopup, showBoostModal, showSaveTranscriptsModal, showZoomBroadcastingWarning, }) => {
    const classes = useStyles({ conversationContainerHovered });
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const fullScreen = useAppSelector(selectFullScreen);
    const insertSpeakerNameMode = useAppSelector(selectInsertSpeakerNameMode);
    const scribing = useAppSelector(selectIsScribing);
    const joiningStatus = useAppSelector(selectJoiningStatus);
    const muted = useAppSelector(selectConversationMuted);
    const isInConversation = useAppSelector(selectIsInConversation);
    const isConversationEnded = useAppSelector(selectConversationEnded);
    const status = useAppSelector(selectStatus);
    const dispatch = useAppDispatch();
    const { notify } = useNotifications();
    useEffect(() => {
        if (joiningStatus === 'accepted') {
            notify(t('conversation.inviteCard.joinedAvaRoom'));
            dispatch(setJoiningStatus('none'));
        }
    }, [joiningStatus]);
    return (React.createElement("div", { style: { display: 'flex' } },
        React.createElement(WebsocketReconnectingBanner, { className: classes.reconnectBanner }),
        insertSpeakerNameMode && (React.createElement("div", { style: { backgroundColor: 'darksalmon', width: '100%', height: 30, textAlign: 'center' } }, "Insert Speaker Name mode")),
        scribing && status.scribe && status.scribe.status === 'leaving' && (React.createElement("div", { style: {
                backgroundColor: 'darksalmon',
                width: '100%',
                height: 30,
                textAlign: 'center',
                padding: 5,
                position: 'relative',
                zIndex: 102,
            } },
            React.createElement("span", null, t('scribeEndRequested')))),
        isInConversation && (React.createElement(SoloDiaNameModalProvider, null,
            React.createElement(ScribeTranscriptListContainer, null),
            React.createElement(SoloDiaSpeakerNameModal, null))),
        window.isElectron && !muted && isInConversation && !scribing && (React.createElement(ScribeVolumeController, { className: classes.volumeController })),
        status.participants && !scribing && !fullScreen && isInConversation && (React.createElement(React.Fragment, null,
            showSaveTranscriptsModal && React.createElement(SaveTranscriptPopup, { callback: handleSaveTranscriptPopup }),
            showBoostModal && React.createElement(BoostPopup, { open: showBoostModal, onClose: handleBoostPopup, editedWords: editedWords }),
            showZoomBroadcastingWarning && (React.createElement(Dialog, { className: classes.zoomBroadcastWarningDialog, open: showZoomBroadcastingWarning, keepMounted: true, onClose: () => ({}) },
                React.createElement(DialogContent, null,
                    React.createElement("div", { className: classes.zoomBroadcastWarningIconWrap },
                        React.createElement(Typography, { className: classes.zoomBroadcastWarningIconText }, "CC")),
                    React.createElement(Typography, { variant: "h4", className: classes.zoomBroadcastWarningTitle, align: "center", gutterBottom: true }, t('conversation.zoomExportWarning.title')),
                    React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('conversation.zoomExportWarning.body1')),
                    React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('conversation.zoomExportWarning.body2'))),
                React.createElement(DialogActions, { className: classes.zoomBroadcastWarningDialogActions },
                    React.createElement(Button, { className: classes.zoomBroadcastWarningButton }, t('conversation.zoomExportWarning.confirm')),
                    React.createElement(Button, { className: classes.zoomBroadcastWarningCancel, variant: "text" }, t('conversation.zoomExportWarning.cancel'))))))),
        !scribing && isInConversation && isConversationEnded && (React.createElement("div", null,
            React.createElement(Toggle, null, () => React.createElement(SaveTranscriptPopup, { callback: handleSaveTranscriptPopup }))))));
};
export default ConversationView;
