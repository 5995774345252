var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CircularProgress, DialogContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Input from '@mui/material/Input';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { scribeDeselectTranscriptWord } from '../../actions';
import Transition from '../../components/Transition';
import { useNotifications } from '../../hooks/useNotifications';
import { selectUserName } from '../../selectors/userProfile';
import { updateUserName } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { getSearchValue } from '../../utils/http';
import * as segment from '../../utils/segment';
const EditProfileModal = (props) => {
    const { action, close, defaultUserName, open, scribeDeselectTranscriptWord, subtitle, t, title } = props;
    const [submitting, setSubmitting] = useState(false);
    const [userName, setUserName] = useState('');
    const dispatch = useDispatch();
    const userNameFromSelector = useAppSelector(selectUserName);
    const userNameFromUrl = getSearchValue(window, 'guestUserName', undefined);
    const { notify } = useNotifications();
    useEffect(() => {
        if (userNameFromSelector === 'web guest' && userNameFromUrl) {
            setUserName(userNameFromUrl);
            setTimeout(() => submit(), 500);
        }
    }, []);
    useEffect(() => {
        if (open) {
            scribeDeselectTranscriptWord();
        }
    }, [open]);
    const handleChange = (ev) => {
        setUserName(ev.target.value);
    };
    const submit = (ev) => __awaiter(void 0, void 0, void 0, function* () {
        if (ev) {
            ev.preventDefault();
        }
        if (userName === undefined) {
            close();
            return;
        }
        if (userName === '') {
            notify(t('profilModal.invalidUserName'), {
                variant: 'error',
            });
            return;
        }
        setSubmitting(true);
        try {
            yield dispatch(updateUserName(userName));
            setSubmitting(false);
            notify(t('profilModal.profilUpdated'), {});
            close({ updated: true });
            segment.track('Updated Name', { Name: userName });
            segment.identify({ name: userName });
        }
        catch (e) {
            setSubmitting(false);
            notify(t('profilModal.failToUpdateProfil'), {
                variant: 'error',
            });
            Sentry.captureException('updateProfilError', e);
        }
    });
    const isValid = () => {
        return userName && userName.length >= 2;
    };
    let defaultNameValue = defaultUserName === undefined ? userNameFromSelector : defaultUserName;
    if (defaultNameValue === 'web guest') {
        defaultNameValue = '';
    }
    return (React.createElement("form", { onSubmit: (ev) => {
            // TODO: Based on how Dialog is implemented, this code most likely
            // never gets triggered.
            submit(ev);
        } },
        React.createElement(Dialog, { open: open, TransitionComponent: Transition, onClose: () => close({ updated: false }), PaperProps: {
                style: {
                    WebkitAppRegion: 'no-drag',
                },
            } },
            React.createElement(DialogContent, null,
                React.createElement(Typography, { variant: "h6" }, title || t('profilModal.profilSettings')),
                React.createElement(Typography, { variant: "subtitle1", style: { color: 'grey' } }, subtitle),
                React.createElement(Input, { defaultValue: defaultNameValue, onChange: handleChange, autoFocus: true, id: "userName", style: { maxWidth: 300, margin: 'auto' }, onFocus: (event) => event.target.select(), onKeyDown: (ev) => {
                        if (['Enter'].includes(ev.key)) {
                            submit(ev);
                        }
                    } })),
            React.createElement(DialogActions, null,
                !submitting && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { variant: "text", onClick: () => close({ updated: false }) }, t('profilModal.cancel')),
                    React.createElement(Button, { disabled: submitting || !isValid(), variant: "contained", type: "submit", onClick: submit, color: "primary" }, action || t('profilModal.save')))),
                submitting && React.createElement(CircularProgress, { size: 28 })))));
};
export default connect(() => ({}), { scribeDeselectTranscriptWord })(withTranslation()(EditProfileModal));
