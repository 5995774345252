var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles, withTheme } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ICON_COMPUTER_AUDIO from '../../../assets/icons/quickSetupComputerAudio.svg';
import ICON_MICROPHONE_PERMISSION from '../../../assets/icons/quickSetupMicrophone.svg';
import PICTO_VIDEO from '../../../assets/images/pictoVideo.svg';
import QUICK_SETUP_AVA from '../../../assets/images/quickSetupAva.png';
import { selectMicrophoneAccess, selectNeedInternalAudioAccess } from '../../selectors/audioV2';
import { setupMacAudioComplete } from '../../store/slices/audioV2';
import { setShowQuickSetup, setUrlToOpenAfterStartingConvo } from '../../store/slices/quickSetup';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getOS } from '../../utils';
import { track, trackMicPermission } from '../../utils/segment';
let micCheckInterval;
const InstallMacInternalAudio = ({ t, classes, createNewConversation, setLangSelected, theme }) => {
    const needInternalAudioAccess = useAppSelector(selectNeedInternalAudioAccess);
    const microphoneAccess = useAppSelector(selectMicrophoneAccess);
    const [step, setStep] = useState(needInternalAudioAccess || microphoneAccess !== 'granted' ? 1 : 4);
    const [micStatus, setMicStatus] = useState(microphoneAccess);
    const [driverInstalling, setDriverInstalling] = useState(false);
    const [driverInstalled, setDriverInstalled] = useState(false);
    const [error, setError] = useState(false);
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const isDarkMode = theme.palette.mode === 'dark';
    const INSTALL_MICROPHONE = isDarkMode ? '/setupAudio/installMicrophoneDark.gif' : '/setupAudio/installMicrophone.gif';
    const INSTALL_INTERNAL_AUDIO = isDarkMode
        ? '/setupAudio/installInternalAudioDark.gif'
        : '/setupAudio/installInternalAudio.gif';
    const OPEN_SYSTEM_PREFS = isDarkMode ? '/setupAudio/openSystemPrefsDark.gif' : '/setupAudio/openSystemPrefs.gif';
    useEffect(() => {
        // When we display this component, we resize the window
        if (window.electronIPC) {
            window.electronIPC.sendSetupAudioLoaded();
        }
    }, []);
    useEffect(() => {
        if (step === 4 && window.electronIPC) {
            window.electronIPC.sendGoInFloatingMode();
        }
    }, [step]);
    const setupMacMicrophone = useCallback(() => {
        window.electronIPC.onceMacMicrophoneAccessResult((event, macMicPermission) => {
            if (macMicPermission) {
                setMicStatus('granted');
                trackMicPermission('granted');
            }
            else {
                setMicStatus('denied');
                trackMicPermission('denied');
            }
        });
        window.electronIPC.sendAskMacMicrophoneAccess();
    }, [setMicStatus]);
    const checkMicPermissionChange = () => __awaiter(void 0, void 0, void 0, function* () {
        const audioSetup = yield window.electronIPC.invokeCheckAudioSetup();
        if (audioSetup.micAccess === 'granted' && micStatus !== audioSetup.micAccess) {
            setMicStatus('granted');
            trackMicPermission('granted');
            clearInterval(micCheckInterval);
            micCheckInterval = null;
        }
    });
    const openMicSystemPreferences = () => {
        if (window.desktopIntegration) {
            const micPreferencesUrl = getOS() === 'Mac'
                ? 'x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone'
                : 'ms-settings:privacy-microphone';
            window.desktopIntegration.openExternalURL(micPreferencesUrl);
            if (getOS() !== 'Mac') {
                // On windows, no need to reboot app, so
                // let's check permissions regularly
                micCheckInterval = setInterval(() => {
                    checkMicPermissionChange();
                }, 500);
            }
        }
    };
    const installInternalAudio = useCallback(() => {
        if (window.electronIPC) {
            setDriverInstalling(true);
            window.electronIPC.onceMacInternalAudioInstallation((event, installResult) => {
                Sentry.captureException(`\n\nMAC INTERNAL AUDIO INSTALLATION RESULT:\n\n${JSON.stringify(installResult)}\n\n`);
                setDriverInstalling(false);
                if (installResult.isInstalled) {
                    track('Audio Driver Downloaded');
                    setDriverInstalled(true);
                }
                else {
                    setError(true);
                }
            });
            window.electronIPC.sendSetupMacInternalAudio();
        }
    }, [setDriverInstalling]);
    const resizeWindowToDefault = () => {
        if (window.desktopIntegration) {
            window.desktopIntegration.resizeWindowToDefault();
        }
    };
    const tryAva = (launchVideo) => {
        const interfaceLang = i18n.language;
        resizeWindowToDefault();
        if (launchVideo) {
            dispatch(setUrlToOpenAfterStartingConvo(interfaceLang === 'fr' ? 'https://www.ava.me/fr/cc-video' : 'https://www.ava.me/cc-video'));
        }
        // AvaMic will be automatically selected if it's available
        createNewConversation();
        dispatch(setShowQuickSetup(false));
        dispatch(setupMacAudioComplete());
    };
    if (error) {
        Sentry.captureException(error);
    }
    if (step === 1) {
        return (React.createElement("div", { className: classes.container },
            React.createElement(Typography, { align: "center", className: classes.setup }, t('macInternalAudio.quickSetup')),
            React.createElement(Typography, { align: "center", className: classes.titleHome }, t('macInternalAudio.permissionsTitle')),
            React.createElement(Typography, { align: "center", className: classes.subtitle },
                needInternalAudioAccess && (React.createElement("div", { className: classes.permission },
                    React.createElement("img", { src: ICON_COMPUTER_AUDIO, alt: "computer audio icon", className: classes.permissionImg }),
                    React.createElement("span", { className: classes.permissionName }, t('macInternalAudio.internalAudio')))),
                micStatus !== 'granted' && (React.createElement("div", { className: classes.permission },
                    React.createElement("img", { src: ICON_MICROPHONE_PERMISSION, alt: "mic permission icon", className: classes.permissionImg }),
                    React.createElement("span", { className: classes.permissionName }, t('macInternalAudio.microphone'))))),
            React.createElement(Button, { className: classes.enable, onClick: () => setStep(needInternalAudioAccess ? 2 : 3) }, t('macInternalAudio.continue'))));
    }
    const MIC_IMAGE = micStatus === 'not-determined' ? INSTALL_MICROPHONE : OPEN_SYSTEM_PREFS;
    let micImgClass = '';
    if (micStatus === 'granted') {
        micImgClass = classes.greenTick;
    }
    else if (micStatus === 'denied') {
        micImgClass = classes.systemPrefs;
    }
    if (step === 2) {
        return (React.createElement("div", { className: classes.container },
            React.createElement(Typography, { align: "center", className: classes.setup }, t('macInternalAudio.quickSetup')),
            React.createElement("img", { src: driverInstalled ? '/setupAudio/avaSetupSuccess.svg' : INSTALL_INTERNAL_AUDIO, alt: "enable internal audio", className: `${classes.image} ${driverInstalled ? classes.greenTick : ''}` }),
            React.createElement(Typography, { align: "center", className: classes.title }, t(driverInstalled ? 'macInternalAudio.allowInternalAudioSuccess' : 'macInternalAudio.allowInternalAudio')),
            driverInstalling && (React.createElement(Button, { className: classes.enable, disabled: true }, t('macInternalAudio.installingDriver'))),
            !driverInstalling && (React.createElement(Button, { className: classes.enable, onClick: () => {
                    if (!driverInstalled) {
                        installInternalAudio();
                    }
                    else if (micStatus !== 'granted') {
                        setStep(3);
                    }
                    else {
                        setStep(4);
                    }
                } }, t(driverInstalled ? 'macInternalAudio.continue' : 'macInternalAudio.installDriver')))));
    }
    if (step === 3) {
        return (React.createElement("div", { className: classes.container },
            React.createElement(Typography, { align: "center", className: classes.setup }, t('macInternalAudio.quickSetup')),
            React.createElement("img", { src: micStatus === 'granted' ? '/setupAudio/avaSetupSuccess.svg' : MIC_IMAGE, alt: "enable microphone", className: `${classes.image} ${micImgClass}` }),
            React.createElement(Typography, { align: "center", className: classes.title },
                micStatus === 'granted' && t('macInternalAudio.allowMicrophoneSuccess'),
                micStatus === 'not-determined' && t('macInternalAudio.allowMicrophone'),
                micStatus === 'denied' && t('macInternalAudio.allowMicrophoneInSystemPrefs')),
            React.createElement(Button, { className: classes.enable, onClick: () => {
                    if (micStatus === 'not-determined') {
                        setupMacMicrophone();
                    }
                    else if (micStatus === 'denied') {
                        openMicSystemPreferences();
                    }
                    else {
                        setStep(4);
                    }
                } },
                micStatus === 'not-determined' && t('macInternalAudio.enablePermissions'),
                micStatus === 'denied' && t('macInternalAudio.openSystemPrefs'),
                micStatus === 'granted' && t('macInternalAudio.continue'))));
    }
    return (React.createElement("div", { className: classes.container },
        React.createElement(Typography, { align: "center", className: classes.setup }, t('macInternalAudio.quickSetup')),
        React.createElement(Typography, { align: "center", className: classes.titleHome }, t('macInternalAudio.done')),
        React.createElement(Typography, { align: "center", className: classes.doneSubtitle }, t('macInternalAudio.tryItNow')),
        React.createElement("div", { style: { textAlign: 'center' } },
            React.createElement("img", { src: QUICK_SETUP_AVA, style: { width: '100%', maxWidth: 200 }, alt: "picto mic" })),
        React.createElement(Button, { className: `${classes.enable} ${getOS() === 'Mac' ? classes.drag : ''}`, onClick: () => tryAva(true) },
            React.createElement("img", { src: PICTO_VIDEO, className: classes.pictoVideo, alt: "picto video" }),
            ' ',
            t('macInternalAudio.startAva')),
        React.createElement(Typography, { align: "center", className: classes.tryLater, onClick: () => tryAva(false) }, t('macInternalAudio.tryLater'))));
};
const styles = (theme) => ({
    container: {
        paddingTop: 70,
        maxWidth: 800,
        margin: 'auto',
        paddingBottom: 30,
        paddingRight: 80,
        paddingLeft: 80,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleHome: {
        color: theme.palette.ava.blue,
        fontSize: 34,
        marginTop: theme.spacing(1),
        fontWeight: 800,
        position: 'relative',
        zIndex: 2,
    },
    title: {
        color: theme.palette.contrast,
        fontSize: 28,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        position: 'relative',
        zIndex: 2,
    },
    setup: {
        color: '#778290',
        fontSize: 26,
        fontWeight: 'bolder',
        marginTop: theme.spacing(1),
        position: 'relative',
        zIndex: 2,
    },
    subtitle: {
        color: 'white',
        fontWeight: 500,
        fontSize: 26,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        position: 'relative',
        zIndex: 2,
        maxWidth: 500,
    },
    enable: {
        textTransform: 'none',
        color: 'white',
        borderRadius: 33,
        backgroundColor: theme.palette.ava.blue,
        fontSize: 26,
        fontWeight: 800,
        borderLeftStyle: 'solid !important',
        borderColor: 'lightgray !important',
        marginBottom: 12,
        '&:hover': {
            backgroundColor: theme.palette.ava.blue,
            color: 'white',
        },
        padding: '8px 30px',
        whiteSpace: 'nowrap',
        margin: 'auto',
        display: 'flex',
        marginTop: theme.spacing(5),
        minWidth: 320,
    },
    image: {
        margin: 'auto',
        display: 'block',
        maxHeight: 400,
        maxWidth: '100%',
        marginTop: -theme.spacing(5),
        marginBottom: -theme.spacing(5),
        zIndex: 0,
        position: 'relative',
    },
    greenTick: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        height: 100,
    },
    systemPrefs: {
        maxHeight: 800,
        marginTop: -theme.spacing(2),
        marginBottom: -theme.spacing(2),
    },
    pictoVideo: {
        marginRight: theme.spacing(2),
    },
    doneSubtitle: {
        color: '#778290',
        fontSize: 24,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    confirmationMessage: {
        background: 'linear-gradient(90deg, #263252 7.32%, #272728 82.56%)',
        borderRadius: 24,
        padding: '14px 20px',
        color: 'white',
        fontSize: 25,
    },
    pictoMic: {
        width: 50,
        height: 50,
        marginRight: theme.spacing(3),
    },
    drag: {
        WebkitAppRegion: 'drag',
    },
    tryLater: {
        color: theme.palette.ava.blue,
        fontSize: 20,
        marginTop: theme.spacing(2),
        fontWeight: 800,
        position: 'relative',
        cursor: 'pointer',
    },
    permission: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    permissionName: {
        display: 'inline-block',
        backgroundColor: '#2F3B59',
        opacity: 0.95,
        padding: 7,
        borderRadius: 40,
        height: 45,
        fontSize: 18,
        width: 230,
        marginLeft: 15,
    },
    permissionImg: {
        height: 75,
    },
});
export default withStyles(styles)(withTheme(InstallMacInternalAudio));
