import { sumBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
export const useAutoScroll = (scrollableContainer) => {
    const lastScrollTop = useRef(0);
    const lastScrollsUp = useRef([]);
    const touching = useRef(false);
    // This is needed for callbacks that don't have accesss to fresh state
    const followingTranscriptRef = useRef(true);
    const [isAtBottomOfTranscript, setIsAtBottomOfTranscript] = useState(true);
    const [scrollHeightWhenStoppedFollowing, setScrollHeightWhenStoppedFollowing] = useState(0);
    const [newTranscriptsWhenNotFollowing, setNewTranscriptsWhenNotFollowing] = useState(false);
    // When was the user-initiated scrolling started. This is important, because
    // some time passes between receiving 'wheel' event and the browser actually
    // performing the scroll. It's important that in this time we do not check
    // whether we are not at the bottom or not - because the user-initiated
    // scroll has just not come through yet.
    const scrollInitiatedRef = useRef(Date.now());
    const showMoreButton = !isAtBottomOfTranscript && !followingTranscriptRef.current && newTranscriptsWhenNotFollowing;
    const update = () => {
        // TODO: Consider using MutationObserver instead of manually calling update()
        updateIsAtBottomOfTranscript();
        updateNewTranscriptsWhenNotFollowing();
        followIfShouldFollow();
    };
    const follow = () => {
        lastScrollsUp.current = [];
        followingTranscriptRef.current = true;
    };
    useEffect(() => {
        if (followingTranscriptRef.current)
            followIfShouldFollow();
    }, [followingTranscriptRef.current]);
    useEffect(() => {
        const container = scrollableContainer.current;
        if (!container)
            return;
        const onWheel = (wheelEvent) => {
            if (wheelEvent.deltaY >= 0) {
                // Scroll down or sideways.
                return;
            }
            // Scroll up, we should stop following.
            if (followingTranscriptRef.current) {
                followingTranscriptRef.current = false;
                setScrollHeightWhenStoppedFollowing(container ? container.scrollHeight : 0);
                setNewTranscriptsWhenNotFollowing(false);
                scrollInitiatedRef.current = Date.now();
            }
        };
        const onScroll = () => {
            updateIsAtBottomOfTranscript();
            // We only listen to scroll events during touch events - in other situations
            // we rely on the `wheel` event.
            if (!touching.current)
                return;
            const newScrollTop = container.scrollTop;
            const oldScrollTop = lastScrollTop.current || 0;
            lastScrollTop.current = newScrollTop;
            if (newScrollTop > oldScrollTop) {
                // Scrolling down which means we cannot stop following.
                return;
            }
            const now = new Date().getTime();
            lastScrollsUp.current.push({
                when: now,
                scroll: oldScrollTop - newScrollTop,
            });
            // We keep all the scrolls of the last second
            lastScrollsUp.current = lastScrollsUp.current.filter((scroll) => now - scroll.when < 1000);
            const amountOfScroll = sumBy(lastScrollsUp.current, 'scroll');
            if (amountOfScroll > 50 && followingTranscriptRef.current) {
                followingTranscriptRef.current = false;
                setScrollHeightWhenStoppedFollowing(container ? container.scrollHeight : 0);
                setNewTranscriptsWhenNotFollowing(false);
                scrollInitiatedRef.current = Date.now();
            }
        };
        const onTouchStart = () => {
            touching.current = true;
        };
        const onTouchEnd = () => {
            touching.current = false;
        };
        if (!container)
            return;
        container.addEventListener('wheel', onWheel);
        container.addEventListener('scroll', onScroll);
        container.addEventListener('touchstart', onTouchStart);
        container.addEventListener('touchend', onTouchEnd);
        return () => {
            container.removeEventListener('wheel', onWheel);
            container.removeEventListener('scroll', onScroll);
            container.removeEventListener('touchstart', onTouchStart);
            container.removeEventListener('touchend', onTouchEnd);
        };
    }, [scrollableContainer.current]);
    const followIfShouldFollow = () => {
        if (!followingTranscriptRef.current) {
            return;
        }
        // setTimeout(..., 0) makes the browser execute the code directly after
        // this execution finishes. Without it the browser is not done rendering
        // the container and is unable to scroll it.
        setTimeout(() => {
            if (!scrollableContainer.current)
                return;
            scrollableContainer.current.scrollTop = scrollableContainer.current.scrollHeight;
        }, 0);
    };
    const updateIsAtBottomOfTranscript = () => {
        if (Date.now() - scrollInitiatedRef.current < 100)
            return;
        if (!scrollableContainer.current)
            return;
        const newScrollTop = scrollableContainer.current.scrollTop;
        const scrollDistanceFromBottom = scrollableContainer.current.scrollHeight - (newScrollTop + scrollableContainer.current.clientHeight);
        const newIsAtBottomOfTranscript = scrollDistanceFromBottom < 30;
        setIsAtBottomOfTranscript(newIsAtBottomOfTranscript);
        if (newIsAtBottomOfTranscript && !followingTranscriptRef.current) {
            followingTranscriptRef.current = true;
        }
        return newIsAtBottomOfTranscript;
    };
    const updateNewTranscriptsWhenNotFollowing = () => {
        if (followingTranscriptRef.current)
            return;
        const newTranscripts = scrollHeightWhenStoppedFollowing !== (scrollableContainer.current && scrollableContainer.current.scrollHeight);
        if (newTranscriptsWhenNotFollowing !== newTranscripts) {
            setNewTranscriptsWhenNotFollowing(newTranscripts);
        }
    };
    return {
        update,
        follow,
        showMoreButton,
    };
};
