import 'audioworklet-polyfill';
import React, { useEffect } from 'react';
import useMicPermission from '../../hooks/useMicPermission';
import { useNotifications } from '../../hooks/useNotifications';
import { selectAudioV2Error, selectAudioV2Status, selectVolume } from '../../selectors/audioV2';
import { selectCaptionQuality } from '../../selectors/conversation';
import { selectElectronCaptionMode, selectFullScreen } from '../../selectors/ui';
import { selectAvaId } from '../../selectors/userProfile';
import { RecordingStatus, stopRecording } from '../../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { hasScribe } from '../../utils/status';
import { AudioErrorType } from '../AudioV2/errors';
import AudioRecorderUI from './AudioRecorderUI';
const AudioRecorder = (props) => {
    const { hideButton, showExpandedMic, status, t, theme } = props;
    const { notify } = useNotifications();
    useMicPermission();
    const avaId = useAppSelector(selectAvaId);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const selectedCaptions = useAppSelector(selectCaptionQuality);
    const recordingStatus = useAppSelector(selectAudioV2Status);
    const fullScreen = useAppSelector(selectFullScreen);
    const volume = useAppSelector(selectVolume);
    const audioV2Error = useAppSelector(selectAudioV2Error);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!audioV2Error)
            return;
        let errorMessage = '';
        if (audioV2Error.audioErrorType === AudioErrorType.NO_AUDIO_TRACKS) {
            errorMessage = t('snackbar.request.shareAudioNeeded');
        }
        else {
            errorMessage = t('snackbar.request.microphoneMissing');
        }
        notify(errorMessage, {
            variant: 'error',
            preventDuplicate: true,
            autoHideDuration: 10000,
        });
    }, [audioV2Error]);
    useEffect(() => {
        // Conversation's audio streams can be updated remotely and backend sends this info
        // via 'room-status-update' message. Here we are interested if our audio has been
        // muted remotely.
        //
        // There could be more than one stream for each participant. We check the last (latest)
        // for this user.
        const myStreams = (status.audioStreams || []).filter((stream) => stream.id === avaId);
        if (myStreams.length > 0) {
            const [last] = myStreams.slice(-1);
            if (last.forceMuted && recordingStatus === RecordingStatus.RECORDING) {
                dispatch(stopRecording());
                notify(t('conversation.notifications.youHaveBeenMuted'));
            }
        }
    }, [status.audioStreams]);
    /*
    This logic is partially copied from AudioRecorderUI. The purpose of putting it
    here is to reduce the number of React state changed when `volume` changes.
    `volume` is updated 5-8 times a second, and reducing the size of the tree
    that is updated as that happens helps performance.
     */
    const showMinimalMic = window.isElectron && electronCaptionMode && !showExpandedMic;
    let shadowColor;
    if (recordingStatus === RecordingStatus.RECORDING) {
        if (hasScribe(status)) {
            shadowColor = theme.palette.ava.green;
        }
        else if (selectedCaptions === undefined || selectedCaptions === 'premium') {
            shadowColor = theme.palette.ava.gradientBlue;
            shadowColor = theme.palette.ava.darkBlue;
        }
        else {
            shadowColor = theme.palette.ava.blue;
        }
    }
    // Volume is currently between 0 and 128, but this is browser-dependent
    const shadowSpread = Math.min(8, Math.floor(Math.log2(volume) * 3));
    const width = showMinimalMic ? 20 : 56;
    const height = showMinimalMic ? 20 : 56;
    const positionLeft = window.isElectron && electronCaptionMode ? `calc(50% - ${width / 2}px)` : 0;
    const positionTop = window.isElectron && electronCaptionMode ? `calc(50% - ${height / 2 - 2}px)` : 0;
    return (React.createElement("div", { style: {
            visibility: fullScreen && !electronCaptionMode ? 'hidden' : 'visible',
            display: hideButton ? 'none' : 'inherit',
        } },
        React.createElement("div", { style: {
                position: 'absolute',
                left: positionLeft,
                top: positionTop,
                height,
                width,
                minHeight: height,
                borderRadius: '50%',
                transition: 'all 120ms ease-in 0s, box-shadow 250ms ease-in 0s',
                boxShadow: recordingStatus === RecordingStatus.RECORDING
                    ? `0px 0px 5px ${shadowSpread}px ${shadowColor}90`
                    : 'black 0px 0px 0px 0px',
            } }),
        React.createElement(AudioRecorderUI, Object.assign({}, props, { showMinimalMic: showMinimalMic }))));
};
export default AudioRecorder;
