import { withTheme } from '@mui/styles';
import React from 'react';
import { scribeSelectTranscriptWord } from '../../actions';
import { useAppDispatch } from '../../store/store';
const parseWord = (word, classes = '', cursor, participants) => {
    let participant = {};
    (participants || []).forEach((p) => {
        if ((cursor || []).includes(p.hash)) {
            participant = p;
        }
    });
    if (word[0] === '`' && word[word.length - 1] === '`') {
        return parseWord(word.slice(1, word.length - 1), `${classes} tr-edited`);
    }
    if (word[0] === '~' && word[word.length - 1] === '~') {
        return parseWord(word.slice(1, word.length - 1), `${classes} tr-disabled`);
    }
    const shownWord = cursor && !word ? ' ' : word;
    return (React.createElement(React.Fragment, null,
        participant.avaId && (React.createElement("span", { style: {
                position: 'absolute',
                fontSize: 12,
                marginTop: -14,
                borderLeft: 'solid',
                borderWidth: 2,
                borderColor: cursor ? 'darkred' : undefined,
                paddingLeft: 3,
                backgroundColor: 'rgba(10,10,10,0.5)',
                pointerEvents: 'none',
            } }, participant.userName)),
        React.createElement("span", { className: classes, id: participant.userName, style: {
                backgroundColor: cursor ? 'darkred' : undefined,
                color: participant.avaId ? 'white' : undefined,
            } }, shownWord)));
};
const ScribeWord = ({ allowEdit, cursor, highlighted, participants, scribe, theme, transcriptId, unlocked, word, wordIndex, asrFinal, color, singleSpeaker, electronCaptionMode, }) => {
    const dispatch = useAppDispatch();
    return (React.createElement("span", null,
        React.createElement("span", { style: {
                whiteSpace: 'pre-wrap',
                backgroundColor: scribe && (!asrFinal || unlocked) ? theme.palette.ava.asrGrey : 'transparent',
                fontWeight: theme.palette.bold === 'true' || highlighted ? 'bold' : undefined,
                fontStyle: !highlighted && !asrFinal && scribe ? 'italic' : 'normal',
                color: !highlighted && asrFinal && scribe
                    ? color
                    : !highlighted && scribe && theme.palette.mode === 'light'
                        ? theme.palette.ava.asrGrey2
                        : !highlighted && scribe && theme.palette.mode === 'dark'
                            ? theme.palette.ava.grey7
                            : electronCaptionMode || (singleSpeaker && theme.palette.mode === 'dark')
                                ? theme.palette.ava.white
                                : color,
            }, onClick: () => {
                if (allowEdit) {
                    dispatch(scribeSelectTranscriptWord({
                        index: wordIndex,
                        indexTranscript: transcriptId,
                        skipCommit: true,
                        skipJumpNextBloc: true,
                    }));
                }
            }, role: "button", "data-qa": "captions" }, parseWord(word, '', allowEdit ? cursor : undefined, participants)),
        ' '));
};
export default withTheme(ScribeWord);
