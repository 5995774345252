import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../components/Buttons/RoundedButton';
const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        boxShadow: '2px 2px 8px 0px rgba(112, 144, 176, 0.35)',
        margin: (props) => (props.firstButton && props.size !== 'small' ? '0px 0px 8px 0px ' : '8px 0px'),
    },
    phoneIcon: {
        color: theme.palette.ava.deepBlue,
        width: 24,
        height: 24,
    },
    text: {
        fontSize: (props) => (props.size === 'medium' ? 18 : 14),
        fontWeight: 600,
    },
    blueButton: {
        display: 'flex',
        background: 'radial-gradient(120.92% 106.78% at 0% 0%, rgba(134, 190, 255, 0.98) 0%, rgba(134, 205, 255, 0.98) 0.01%, #50ACED 100%)',
        color: theme.palette.ava.white,
        transition: 'background 0.3s ease-in-out',
        '&:hover': {
            background: 'radial-gradient(120.92% 106.78% at 0% 0%, rgba(173, 216, 255, 0.98) 0%, rgba(173, 226, 255, 0.98) 0.01%, #65BEF7 100%)',
        },
        '&:disabled': {
            opacity: 0.6,
            color: theme.palette.ava.white,
        },
    },
}));
function SignUpSignInButton({ disabled, showPasswordField, showRestOfAccountCreation, forProvider, icon, altText, onClick, className, text, style, textStyle, phone, signup, firstButton, size, }) {
    const classes = useStyles({ firstButton, size });
    const { t } = useTranslation();
    return (React.createElement(RoundedButton, { onClick: onClick || undefined, type: forProvider ? 'button' : 'submit', disabled: disabled, className: classNames(classes.button, className, { [classes.blueButton]: signup }), style: style },
        icon && React.createElement("img", { src: icon, alt: altText }),
        phone && React.createElement(LocalPhoneIcon, { className: classes.phoneIcon }),
        React.createElement(Typography, { className: classNames(classes.text, textStyle) }, text
            ? text
            : showPasswordField && !showRestOfAccountCreation
                ? t('onboardingV2.signin.continueWithEmail')
                : t('onboardingV2.continue'))));
}
export default SignUpSignInButton;
