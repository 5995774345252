import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSingletonRecallAIManager } from '../../utils/recallAI';
const INITIAL_STATE = {
    recallAIManager: undefined,
    recallAIStatus: 'NOT_CONNECTED',
    errorMessage: undefined,
    service: '',
    host: undefined,
};
const recallAI = createSlice({
    name: 'recallAI',
    initialState: INITIAL_STATE,
    reducers: {
        setRecallAIStatus: (state, action) => {
            const { status, errorMessage } = action.payload;
            state.recallAIStatus = status;
            state.errorMessage = errorMessage;
        },
        setRecallAIService: (state, { payload }) => {
            state.service = payload;
        },
        setIsRecallAIHost: (state, { payload }) => {
            state.host = payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(createRecallAIManager.fulfilled, (state, { payload }) => {
            state.recallAIManager = payload;
        });
    },
});
export const recallAIReducer = recallAI.reducer;
export const { setRecallAIStatus, setRecallAIService, setIsRecallAIHost } = recallAI.actions;
export const createRecallAIManager = createAsyncThunk('recallAI/createRecallAIManager', ({ ws, dispatch }) => {
    return getSingletonRecallAIManager(ws, dispatch);
});
