import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppleLogoIcon from '../../../assets/icons/apple_logo.svg';
import FacebookIcon from '../../../assets/icons/facebook_logo_white.svg';
import GoogleLogoIcon from '../../../assets/icons/google_logo.svg';
import SignUpSignInButton from './SignUpSignInButton';
const useStyles = makeStyles((theme) => ({
    continueBlue: {
        display: 'flex',
        background: 'radial-gradient(120.92% 106.78% at 0% 0%, rgba(134, 190, 255, 0.98) 0%, rgba(134, 205, 255, 0.98) 0.01%, #50ACED 100%)',
        color: theme.palette.ava.white,
        transition: 'background 0.3s ease-in-out',
        '&:hover': {
            background: 'radial-gradient(120.92% 106.78% at 0% 0%, rgba(173, 216, 255, 0.98) 0%, rgba(173, 226, 255, 0.98) 0.01%, #65BEF7 100%)',
        },
    },
    logoButtonText: {
        marginLeft: 10,
        fontSize: (props) => (props.size === 'small' ? 14 : 18),
    },
    continueWhite: {
        backgroundColor: theme.palette.ava.white,
        color: theme.palette.ava.deepBlue,
    },
}));
function LoginWithProviderButton({ onClick, provider, size }) {
    const classes = useStyles({ size });
    const [className, setClassName] = useState('');
    const [text, setText] = useState('');
    const [altText, setAltText] = useState('');
    const [phone, setPhone] = useState(false);
    const [icon, setIcon] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        switch (provider) {
            case 'apple': {
                setAltText('Apple Icon');
                setText(t('onboardingV2.providers.continueWithApple'));
                setClassName(classes.continueWhite);
                setIcon(AppleLogoIcon);
                break;
            }
            case 'facebook': {
                setClassName(classes.continueBlue);
                setAltText('Facebook Icon');
                setText(t('onboardingV2.providers.continueWithFacebook'));
                setIcon(FacebookIcon);
                break;
            }
            case 'google': {
                setText(t('onboardingV2.providers.continueWithGoogle'));
                setClassName(classes.continueBlue);
                setAltText('Google Icon');
                setIcon(GoogleLogoIcon);
                break;
            }
            case 'phone': {
                setPhone(true);
                break;
            }
        }
    }, [provider]);
    return (React.createElement(SignUpSignInButton, { className: className, disabled: false, forProvider: true, text: text, textStyle: classes.logoButtonText, onClick: onClick, icon: icon, altText: altText, phone: phone, size: size }));
}
export default LoginWithProviderButton;
