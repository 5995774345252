/* eslint-disable react-hooks/rules-of-hooks */
import { invoke } from '@tauri-apps/api';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createNewConversation } from '../../actions';
import { useNotifications } from '../../hooks/useNotifications';
import { selectAudioV2Status, selectSelectedMicrophone, selectWebRTCConnectionStatus } from '../../selectors/audioV2';
import { selectIsInConversation } from '../../selectors/conversation';
import { RecordingStatus, setStatus, setVolume, setWebRTCConnectionStatus, stopRecording, } from '../../store/slices/audioV2';
import { setCCMode } from '../../store/slices/ccMode';
import { setElectronCaptionMode } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { isMac } from '../../utils';
import { logoutAndClean } from '../../utils/user';
export const TauriIntegration = () => {
    if (!window.__TAURI__)
        return null;
    const { notify } = useNotifications();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const cancelRecordingTimeoutRef = useRef(undefined);
    const isInConversation = useAppSelector(selectIsInConversation);
    const navigate = useNavigate();
    useEffect(() => {
        const unsubscribe = window.store.subscribe(() => {
            // TODO: Consider throttling this? But it should not be expensive
            // regardless of the frequency.
            invoke('plugin:redux_state|update', { state: window.store.getState() });
        });
        return () => unsubscribe();
    }, []);
    const useEffectWithUnsubscribe = (asyncUnsubscribe, deps) => {
        useEffect(() => {
            const promise = asyncUnsubscribe();
            return () => promise.then((unsubscribe) => unsubscribe());
        }, deps);
    };
    useEffectWithUnsubscribe(() => window.electronIPC.onAudioAllStopped(() => {
        dispatch(setStatus(RecordingStatus.NOT_RECORDING));
    }), []);
    useEffectWithUnsubscribe(() => window.electronIPC.onSignOut(() => {
        logoutAndClean();
    }), []);
    useEffectWithUnsubscribe(() => window.electronIPC.onStartConversation(() => {
        if (isInConversation)
            return;
        if (!window.location.pathname.includes('/web/transcript')) {
            navigate('/web/transcript');
        }
        console.log('starting new conversation');
        createNewConversation()(dispatch, window.store.getState);
        dispatch(setElectronCaptionMode(true));
        dispatch(setCCMode('conversation'));
    }), [isInConversation, dispatch]);
    useEffectWithUnsubscribe(() => window.electronIPC.onTauriWebRTCConnectionStateChange((payload) => {
        // @ts-ignore
        const state = payload.toLowerCase();
        dispatch(setWebRTCConnectionStatus(state));
        if (state === 'closed' || state === 'disconnected' || state === 'failed') {
            dispatch(setStatus(RecordingStatus.NOT_RECORDING));
        }
    }), []);
    useEffectWithUnsubscribe(() => window.electronIPC.onVolumeReceived((payload) => {
        dispatch(setVolume(payload));
        const state = window.store.getState();
        const webRTCConnectionStatus = selectWebRTCConnectionStatus(state);
        if (webRTCConnectionStatus === 'connected' ||
            webRTCConnectionStatus === 'connecting' ||
            webRTCConnectionStatus === 'new') {
            // As long as we are in a healthy RTC state - we keep recording
            // or waiting for connection. 5 seconds after no audio, or
            // bad webRTC state - we stop recording.
            if (cancelRecordingTimeoutRef.current) {
                clearTimeout(cancelRecordingTimeoutRef.current);
            }
            cancelRecordingTimeoutRef.current = setTimeout(() => {
                // After 5 seconds of no audio input, we stop the recording.
                dispatch(stopRecording());
            }, 1000 * 5);
        }
        if (webRTCConnectionStatus === 'connected') {
            dispatch(setStatus(RecordingStatus.RECORDING));
        }
        else if (webRTCConnectionStatus === 'connecting' || webRTCConnectionStatus === 'new') {
            dispatch(setStatus(RecordingStatus.PENDING));
        }
    }), []);
    useEffectWithUnsubscribe(() => window.electronIPC.onAudioOutputChanged((payload) => {
        var _a;
        const newOutputDevice = payload;
        const state = window.store.getState();
        if (selectAudioV2Status(state) === RecordingStatus.RECORDING &&
            ((_a = selectSelectedMicrophone(state)) === null || _a === void 0 ? void 0 : _a.isInternal) &&
            isMac &&
            newOutputDevice !== 'Ava Speakers') {
            dispatch(stopRecording());
            notify(t('tauri.speakerChangedDuringRecording'), {
                variant: 'warning',
            });
        }
    }), [notify]);
    return null;
};
