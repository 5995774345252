import randomMC from 'random-material-color';
import availableLangs from '../../assets/langs.json';
import { updateUserName } from '../store/slices/userProfile';
import { assertScribeConversationStateConsistency } from '../utils/consistence';
import { last, uniq } from '../utils/lodash';
import { computeTextFormLastMutation, getLang, getText, mutate as mutateTranscript } from '../utils/scribeUtils';
const isProd = window.location.href.indexOf('web.ava.me') > -1;
const updateAuthorShow = (state) => {
    const transcriptsToUpdate = {};
    let lt;
    // eslint-disable-next-line
    for (let i = 0; i < state.transcriptsCurrent.length; i++) {
        const transcript = state.transcripts[state.transcriptsCurrent[i]];
        const ltCandidate = state.transcripts[state.transcriptsCurrent[i - 1]];
        if (ltCandidate && getText(ltCandidate, state.lang)) {
            lt = ltCandidate;
        }
        if (i === 0) {
            lt = state.transcripts[state.transcriptsFinal[state.transcriptsFinal.length - 1]];
        }
        const shouldShow = !lt ||
            transcript.author !== lt.author ||
            transcript.trackName !== lt.trackName ||
            transcript.speechLang !== lt.speechLang;
        if (transcript.authorShow !== shouldShow) {
            transcriptsToUpdate[transcript.id] = Object.assign(Object.assign({}, transcript), { authorShow: shouldShow });
        }
    }
    return Object.assign(Object.assign({}, state), { transcripts: Object.assign(Object.assign({}, state.transcripts), transcriptsToUpdate) });
};
const mutate = (state, action) => {
    const transcript = state.transcripts[action.transcriptId];
    const { transcript: newTranscript, indexWord: newIndexWord } = mutateTranscript(transcript, state.ui.selectedWordIndex || 0, action.mutation, action.asr);
    const words = getText(newTranscript, state.lang).split(' ');
    const cursors = transcript.cursors || [];
    const currentMutation = action.mutation;
    const furthestCursorForCurrentMutator = currentMutation.data.mutatorHash && state.furthestObservedCursors[currentMutation.data.mutatorHash];
    let currentTouchedIndex;
    let cursorsUpdated = false;
    if (currentMutation.type === 'cursor') {
        currentTouchedIndex = { tid: action.transcriptId, index: currentMutation.data.index };
        // This is used to distinguish cursors of the current user from the cursors of
        // other users.
        if (state.connectionHash !== currentMutation.data.mutatorHash) {
            cursorsUpdated = true;
            const indexToChange = (cursors || [])
                .map((cursor) => cursor.mutatorHash)
                .indexOf(currentMutation.data.mutatorHash);
            if (indexToChange !== -1) {
                cursors[indexToChange] = currentMutation.data;
            }
            else {
                cursors.push(currentMutation.data);
            }
        }
    }
    if ((action.mutation.type === 'insert' || action.mutation.type === 'delete') &&
        action.mutation.data.lang === state.lang) {
        currentTouchedIndex = { tid: action.transcriptId, index: currentMutation.data.index };
        for (let i = 0; i < cursors.length; i += 1) {
            const movedWord = computeTextFormLastMutation(getText(transcript, action.mutation.data.lang), action.mutation, cursors[i].index);
            cursorsUpdated = true;
            if (cursors[i].index !== -1)
                cursors[i].index = movedWord.indexWord;
        }
    }
    newTranscript.cursors = cursors;
    if (cursorsUpdated) {
        newTranscript.cursors = [...newTranscript.cursors];
    }
    const updatedFurthestObservedCursors = Object.assign({}, state.furthestObservedCursors);
    if (currentTouchedIndex && currentMutation.data.mutatorHash) {
        if (!furthestCursorForCurrentMutator ||
            currentTouchedIndex.tid > furthestCursorForCurrentMutator.tid ||
            (currentTouchedIndex.tid === furthestCursorForCurrentMutator.tid &&
                currentTouchedIndex.index > furthestCursorForCurrentMutator.index)) {
            updatedFurthestObservedCursors[currentMutation.data.mutatorHash] = currentTouchedIndex;
        }
    }
    let newPrevSelectedWordIndex = state.ui.prevSelectedWordIndex;
    let newSelectedWordIndex = state.ui.selectedWordIndex;
    let newSelectedWordInputValue = state.ui.selectedWordInputValue;
    let newSelectedTranscriptIndex = state.ui.selectedTranscriptIndex;
    if (currentMutation.parent === 'lucy' &&
        currentMutation.type === 'insert' &&
        action.asr &&
        newPrevSelectedWordIndex != null &&
        newPrevSelectedWordIndex < words.length) {
        newSelectedWordIndex = newPrevSelectedWordIndex;
        newSelectedWordInputValue = words[newSelectedWordIndex];
        newSelectedTranscriptIndex = newTranscript.id;
    }
    else if (action.asr &&
        state.ui.selectedTranscriptIndex === transcript.id &&
        getLang(newTranscript.speechLang, state.lang) === action.mutation.data.lang) {
        if (typeof state.ui.selectedWordIndex === 'number') {
            newSelectedWordIndex = newIndexWord;
            newSelectedWordInputValue = words[newSelectedWordIndex];
            newSelectedWordIndex = Math.min(newSelectedWordIndex || 0, words.length - 1);
            newSelectedWordInputValue = words[newSelectedWordIndex];
        }
    }
    if (newSelectedWordIndex !== state.ui.selectedWordIndex) {
        newPrevSelectedWordIndex = state.ui.selectedWordIndex;
    }
    let langs = state.langs || [];
    let { lang } = state;
    if (action.mutation.type === 'insert' || action.mutation.type === 'delete') {
        const dlang = action.mutation.data.lang;
        if (!langs.includes(dlang))
            langs = uniq([...langs, dlang]);
    }
    if (langs.length === 1) {
        [lang] = langs;
    }
    return Object.assign(Object.assign({}, state), { lang,
        langs, ui: Object.assign(Object.assign({}, state.ui), { prevSelectedWordIndex: newPrevSelectedWordIndex, selectedWordIndex: newSelectedWordIndex, selectedWordInputValue: newSelectedWordInputValue, selectedTranscriptIndex: newSelectedTranscriptIndex }), furthestObservedCursors: updatedFurthestObservedCursors, transcripts: Object.assign(Object.assign({}, state.transcripts), { [action.transcriptId]: Object.assign(Object.assign({}, newTranscript), { mutations: [Object.assign(Object.assign({}, action.mutation), { timestamp: Date.now() })], mutationsQueuedByHumanMutation: [] }) }) });
};
const availableCodeLanguage = Object.values(availableLangs).map((x) => {
    if (x && x.languageCode && typeof x.languageCode === 'string') {
        return x.languageCode;
    }
    return 'en-US';
});
const navLang = window.navigator.userLanguage || window.navigator.language;
const getInitialState = () => ({
    lang: '~',
    langs: ['~'],
    transcripts: {},
    speakers: {},
    transcriptsFinal: [],
    transcriptsCurrent: [],
    furthestObservedCursors: {},
    conversationInputIsEdited: false,
    speechLang: localStorage.getItem('speechLang') ||
        (availableCodeLanguage.includes(navLang)
            ? navLang
            : availableCodeLanguage.find((lang) => lang.substring(0, navLang.length) === navLang) || 'en-US'),
    status: {},
    ui: {
        lastEditedWordIndex: -1,
        prevSelectedWordIndex: undefined,
    },
});
/* eslint-enable global-require */
const initialState = getInitialState();
const applyQueuedMutationsIfPossible = (state, transcriptId) => {
    if (!transcriptId) {
        return state;
    }
    const parentSpeechBloc = state.transcripts[transcriptId];
    if (!parentSpeechBloc)
        return Object.assign({}, state);
    if (state.conversationInputIsEdited) {
        return Object.assign({}, state);
    }
    (parentSpeechBloc.mutationsQueuedByHumanMutation || []).forEach((mutation) => {
        // eslint-disable-next-line no-param-reassign
        state = mutate(state, { mutation, transcriptId, asr: true });
    });
    return Object.assign({}, state);
};
function legacyConversationReducer(state = initialState, action) {
    var _a, _b, _c;
    switch (action.type) {
        case 'SET_SPEECH_LANG': {
            return Object.assign(Object.assign({}, state), { speechLang: action.lang });
        }
        case 'CLEAR_SCRIBE_CONVERSATION': {
            return Object.assign({}, getInitialState());
        }
        case 'SCRIBE_WORD_EDIT_STARTED': {
            return Object.assign(Object.assign({}, state), { conversationInputIsEdited: true });
        }
        case 'SCRIBE_TRANSCRIPT_SELECT_WORD': {
            return applyQueuedMutationsIfPossible(Object.assign(Object.assign({}, state), { conversationInputIsEdited: false, ui: Object.assign(Object.assign({}, state.ui), { selectedTranscriptCaretPosition: action.caret || 'all', prevSelectedWordIndex: state.ui.selectedWordIndex, selectedWordIndex: action.index, selectedTranscriptIndex: action.transcriptId, selectedWordInputValue: action.wordText }) }), state.ui.selectedTranscriptIndex);
        }
        case 'SCRIBE_TRANSCRIPT_DESELECT_WORD': {
            return applyQueuedMutationsIfPossible(Object.assign(Object.assign({}, state), { conversationInputIsEdited: false, ui: Object.assign(Object.assign({}, state.ui), { prevSelectedWordIndex: undefined, selectedWordIndex: undefined, selectedTranscriptCaretPosition: 'none', selectedTranscriptIndex: undefined }) }), state.ui.selectedTranscriptIndex);
        }
        case 'CONNECTION_SUCCEEDED': {
            return Object.assign(Object.assign({}, state), { connectionHash: action.status });
        }
        case 'RECEIVED_SCRIBE_CONVERSATION_TRANSCRIPT_MUTATION': {
            const { transcriptId, mutation, origin } = action;
            // eslint-disable-next-line
            let { speakerId } = action;
            if (mutation.type === 'changeSpeaker') {
                ({ speakerId } = mutation.data);
            }
            let { transcriptsCurrent } = state;
            let { transcriptsFinal } = state;
            const parentSpeechBloc = state.transcripts[transcriptId];
            if (state.transcriptsFinal.includes(transcriptId) && mutation.parent === 'lucy') {
                transcriptsCurrent = [...transcriptsCurrent, transcriptId].sort(
                // $FlowFixMe
                (a, b) => a - b);
                transcriptsFinal = transcriptsFinal.filter((tid) => tid !== transcriptId);
                parentSpeechBloc.mutationsQueuedByHumanMutation = [];
                parentSpeechBloc.mutations = [];
                parentSpeechBloc.texts = {};
            }
            if (parentSpeechBloc &&
                mutation.parent !== 'lucy' &&
                last(parentSpeechBloc.mutations || []) &&
                ((_a = last(parentSpeechBloc.mutations || [])) === null || _a === void 0 ? void 0 : _a.id) !== mutation.parent &&
                (!last(parentSpeechBloc.mutationsQueuedByHumanMutation || []) ||
                    ((_b = last(parentSpeechBloc.mutationsQueuedByHumanMutation || [])) === null || _b === void 0 ? void 0 : _b.id) !== mutation.parent)) {
                return state;
            }
            const mutationWithTimestamp = Object.assign(Object.assign({}, mutation), { origin, timestamp: Date.now() });
            if (action.mutation.type === 'insert' || action.mutation.type === 'delete') {
                // eslint-disable-next-line no-param-reassign
                action.mutation.data.lang = action.mutation.data.lang || state.lang;
            }
            // Don't apply ASR mutation when user edit input in this transcript
            if (state.conversationInputIsEdited && state.ui.selectedTranscriptIndex === transcriptId && origin !== 'human') {
                return Object.assign(Object.assign({}, state), { transcripts: Object.assign(Object.assign({}, state.transcripts), { [transcriptId]: Object.assign(Object.assign({}, parentSpeechBloc), { mutationsQueuedByHumanMutation: [...(parentSpeechBloc.mutationsQueuedByHumanMutation || []), mutation] }) }), transcriptsCurrent,
                    transcriptsFinal });
            }
            if (!parentSpeechBloc) {
                const lastTranscript = state.transcripts[last(state.transcriptsCurrent)];
                const authorShow = !lastTranscript || (speakerId && lastTranscript.author !== speakerId);
                return mutate(Object.assign(Object.assign({}, state), { transcriptsCurrent: [...state.transcriptsCurrent, transcriptId].sort(
                    // $FlowFixMe
                    (a, b) => a - b), transcripts: Object.assign(Object.assign({}, state.transcripts), { [transcriptId]: {
                            id: transcriptId,
                            isFinal: false,
                            index: 0,
                            author: speakerId || mutation.data.speakerId || 'Callee',
                            authorShow,
                            origin,
                            mutationsQueuedByHumanMutation: [],
                            mutations: [mutationWithTimestamp],
                            speechLang: state.lang,
                            texts: {
                                [state.lang]: '',
                            },
                        } }) }), {
                    mutation: action.mutation,
                    transcriptId,
                    asr: action.origin === 'asr',
                });
            }
            if (!parentSpeechBloc.mutations) {
                return state;
            }
            return updateAuthorShow(mutate(Object.assign(Object.assign({}, state), { transcriptsCurrent,
                transcriptsFinal }), {
                mutation: action.mutation,
                transcriptId: action.transcriptId,
                asr: action.origin === 'asr',
            }));
        }
        case 'SCRIBE_CREATE_TRANSCRIPT': {
            const newState = Object.assign(Object.assign({}, state), { transcripts: Object.assign(Object.assign({}, state.transcripts), { [action.id]: {
                        id: action.id,
                        isFinal: false,
                        origin: 'human',
                        author: action.author || 'Callee',
                        authorShow: false,
                        mutationsQueuedByHumanMutation: [],
                        mutations: [action.mutation],
                        speechLang: action.lang,
                        texts: {
                            [action.lang]: '',
                        },
                    } }), transcriptsCurrent: [...state.transcriptsCurrent, action.id].sort(
                // $FlowFixMe
                (a, b) => a - b) });
            return updateAuthorShow(mutate(Object.assign({}, newState), {
                mutation: action.mutation,
                transcriptId: action.id,
                asr: false,
            }));
        }
        case 'SCRIBE_SET_LAST_EDITED_WORD_INDEX':
            return Object.assign(Object.assign({}, state), { ui: Object.assign(Object.assign({}, state.ui), { lastEditedWordIndex: Math.max(action.lastEditedWordIndex, state.ui.lastEditedWordIndex) }) });
        case 'SCRIBE_TRANSCRIPT_UPDATE_WORD_INPUT_VALUE':
            return Object.assign(Object.assign({}, state), { ui: Object.assign(Object.assign({}, state.ui), { selectedWordInputValue: action.inputValue, selectedTranscriptCaretPosition: action.caret }) });
        case 'SWITCH_LANG':
            return Object.assign(Object.assign({}, state), { lang: action.lang });
        case 'ROOM_STATUS_UPDATE': {
            let { speakers } = state;
            let possiblyMyselfIsSet = false;
            (action.status.speakers || []).forEach((speaker) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                const oldSpeaker = speakers[speaker.avaId];
                // If avaId starts with speaker_ then the speaker is coming from Solo-Dia
                const isSoloDiaSpeaker = speaker.avaId.startsWith('speaker_') && ((_a = action.status.host) === null || _a === void 0 ? void 0 : _a.flags['mono-segmentation']);
                // If avaId !== avaName then the speaker hasn't been named yet.
                const isSoloDiaSpeakerIdentified = isSoloDiaSpeaker && speaker.avaId !== speaker.avaName;
                // If is Solo-Dia speaker and is marked as myself = true. Then speaker is current user.
                const isSoloDiaSpeakerCurrentUser = isSoloDiaSpeaker && speaker.myself;
                let possiblyMyself = isSoloDiaSpeakerCurrentUser;
                /*
                  We want to identify the first unidentified speaker as being possibly the current user. Once we've
                  identified the first unidentified speaker as possibly the current user then ignore all following unidentified
                  speakers.
                  Unidentified speakers meaning speakers identified by Solo-Dia but aren't named, i.e. Spearker 1, Speaker 2.
                */
                if (!isSoloDiaSpeakerIdentified && !possiblyMyself && !possiblyMyselfIsSet) {
                    possiblyMyself = true;
                    possiblyMyselfIsSet = true;
                }
                speakers = Object.assign(Object.assign({}, speakers), { 
                    // validateSpeakerData is here to maintain backwards compatiability with the old
                    // `speaker-information` we used to receive.
                    [speaker.avaId]: Object.assign(Object.assign(Object.assign({}, oldSpeaker), speaker), { avaId: (_b = speaker.avaId) !== null && _b !== void 0 ? _b : oldSpeaker === null || oldSpeaker === void 0 ? void 0 : oldSpeaker.avaId, id: (_c = speaker.avaId) !== null && _c !== void 0 ? _c : oldSpeaker === null || oldSpeaker === void 0 ? void 0 : oldSpeaker.avaId, userName: (_d = speaker.userName) !== null && _d !== void 0 ? _d : oldSpeaker === null || oldSpeaker === void 0 ? void 0 : oldSpeaker.userName, avaName: (_e = speaker.avaName) !== null && _e !== void 0 ? _e : oldSpeaker === null || oldSpeaker === void 0 ? void 0 : oldSpeaker.avaName, userAvatar: (_h = (_f = speaker.photoUrl) !== null && _f !== void 0 ? _f : (_g = speaker.userPhoto) === null || _g === void 0 ? void 0 : _g.url) !== null && _h !== void 0 ? _h : oldSpeaker === null || oldSpeaker === void 0 ? void 0 : oldSpeaker.userAvatar, theme: ((_j = speaker.theme) !== null && _j !== void 0 ? _j : oldSpeaker === null || oldSpeaker === void 0 ? void 0 : oldSpeaker.theme) || {
                            dark_theme: randomMC.getColor({ text: speaker.avaId }),
                            light_theme: randomMC.getColor({ text: speaker.avaId }),
                        }, isSoloDiaSpeaker,
                        isSoloDiaSpeakerCurrentUser,
                        possiblyMyself }) });
            });
            // Default room id has the format avaId_00000000-0000-0000-0000-000000000000
            // if we dont take it into account it will:
            // reset the transcript and wont redirect to transcript page + show rating
            const newRoom = action.status.id &&
                action.status.id !== state.status.id &&
                !action.status.id.includes('00000000-0000-0000-0000-000000000000');
            const status = Object.assign({}, action.status);
            let newLangs = uniq(state.langs.concat(action.status.langs || []));
            if (newLangs.length === state.langs.length) {
                // newLangs must contain all languages of state.langs, becuase it is
                // concatenated. So if the length is the same - it means all the elements
                // are the same. So we don't change the field, so as to not trigger a rerender.
                newLangs = state.langs;
            }
            return Object.assign(Object.assign({}, state), { speakers,
                status, langs: newLangs, lang: state.langs.length === 0 ? action.status.langs[0] : state.lang, ui: Object.assign({}, state.ui), transcripts: newRoom ? {} : state.transcripts, transcriptsFinal: newRoom ? [] : state.transcriptsFinal, transcriptsCurrent: newRoom ? [] : state.transcriptsCurrent });
        }
        case updateUserName.fulfilled.type:
            // @ts-ignore
            const { userName, avaId } = action.payload;
            if (!userName || userName === ((_c = state.speakers[avaId]) === null || _c === void 0 ? void 0 : _c.userName)) {
                return state;
            }
            return Object.assign(Object.assign({}, state), { speakers: Object.assign(Object.assign({}, state.speakers), { [avaId]: Object.assign(Object.assign({}, state.speakers[avaId]), { userName }) }) });
        default:
            // eslint-disable-next-line no-unused-expressions
            action;
            return state;
    }
}
export default function reducerWrapper(state = initialState, action) {
    const newState = legacyConversationReducer(state, action);
    if (!isProd)
        assertScribeConversationStateConsistency(newState);
    return newState;
}
