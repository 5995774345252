var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ZoomDuplicatedTokenImage from '../../../assets/images/zoom_token.png';
import { useNotifications } from '../../hooks/useNotifications';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectBroadcastToZoomToken } from '../../selectors/conversation';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { conversations } from '../../services/api/ava';
import { updateBroadcastToZoomToken } from '../../store/slices/conversation';
import { useAppDispatch, useAppSelector } from '../../store/store';
const intercomLinks = {
    en: 'https://intercom.help/ava/en/articles/3837352-where-can-i-find-the-zoom-api-token',
    fr: 'https://intercom.help/ava/fr/articles/3837352-ou-trouver-le-token-api-de-zoom',
};
const useStyles = makeStyles((theme) => ({
    connectButton: {
        color: 'white',
        display: 'flex',
        flexGrow: 1,
        fontWeight: 600,
        margin: 20,
        marginBottom: 10,
        marginTop: 0,
        textTransform: undefined,
    },
    dialog: {
        width: 600,
    },
    icon: {
        width: 100,
    },
    iconContainer: {
        display: 'flex',
        flexGrow: 1,
    },
    iconContainerClose: {
        borderRadius: 80,
        color: 'white',
        fontSize: 70,
        fontWeight: 800,
        height: 120,
        lineHeight: '120px',
        margin: 'auto',
        marginBottom: 10,
        textAlign: 'center',
        width: 120,
    },
    invitation: {
        display: 'flex',
    },
    progress: {
        height: 10,
        position: 'absolute',
        width: 10,
    },
    title: {
        fontSize: '2rem',
        fontWeight: '600',
        textAlign: 'center',
    },
    whereLink: {
        color: '#337ab7',
        marginBottom: 10,
        textAlign: 'center',
        textDecoration: 'underline',
    },
    tokenWarningDialog: {
        maxWidth: 580,
        margin: '0 auto',
    },
    tokenWarningImage: {
        display: 'block',
        height: 'auto',
        margin: '12px auto 24px',
        width: 180,
    },
    tokenWarningTitle: {
        fontWeight: 700,
    },
    tokenWarningDialogActions: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    tokenWarningButton: {
        '&:hover': {
            background: theme.palette.ava.blue,
            opacity: 0.9,
        },
        background: theme.palette.ava.blue,
        color: theme.palette.background.light,
        fontSize: '1.2em',
        fontWeight: 700,
        margin: '0 auto 16px',
        padding: '0.4em 2.4em',
        textTransform: 'none',
        width: '100%',
    },
}));
export function ZoomBroadcastCaptionsPopup(props) {
    const [apiToken, setApiToken] = React.useState('');
    const [showDuplicateTokenWarning, setShowDuplicateTokenWarning] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const avaId = useAppSelector(selectAvaId);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const broadcastToZoomToken = useAppSelector(selectBroadcastToZoomToken);
    const status = useAppSelector(selectStatus);
    const textFieldRef = useRef(null);
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { notify } = useNotifications();
    const updateApiToken = (token) => {
        const trimmedToken = token.replace(/\s|\n|\r|\r\n/g, '');
        setApiToken(trimmedToken);
    };
    const broadcastToZoom = (setOff) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        dispatch(updateBroadcastToZoomToken(apiToken));
        try {
            yield conversations.exportCaptions({
                avaId: avaId,
                firebaseAuthUID: firebaseUser.uid,
                roomId: status.id,
                apiToken,
            });
            notify(t('conversation.zoomExport.avaClosedCaptionsAddedInZoom'));
        }
        catch (err) {
            notify(err.message, { variant: 'error' });
        }
        finally {
            setShowDuplicateTokenWarning(false);
            setLoading(false);
            setOff();
        }
    });
    const call = (setOff) => {
        if (apiToken === broadcastToZoomToken) {
            setShowDuplicateTokenWarning(true);
        }
        else {
            broadcastToZoom(setOff);
        }
    };
    const inputDisabled = loading;
    const sendDisabled = inputDisabled || apiToken.length < 10;
    const { on, setOff } = props;
    useEffect(() => {
        var _a;
        if (on) {
            (_a = textFieldRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [on]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: on && !showDuplicateTokenWarning, keepMounted: true, onClose: setOff, "aria-labelledby": "form-dialog-title", classes: { paper: classes.dialog } },
            React.createElement(DialogContent, null,
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement("div", { className: classes.iconContainerClose, style: {
                            backgroundColor: theme.palette.ava.blue,
                        } }, "CC")),
                React.createElement(Typography, { className: classes.title }, t('conversation.zoomExport.displayAvaCCinYourZoomCall')),
                React.createElement(Typography, { className: classes.whereLink, variant: "h6" },
                    React.createElement("a", { href: i18n.language === 'fr' ? intercomLinks.fr : intercomLinks.en, rel: "noopener noreferrer", target: "_blank" }, t('conversation.zoomExport.where?'))),
                React.createElement(TextField
                // label="Invitation"
                , { 
                    // label="Invitation"
                    multiline: true, autoFocus: true, minRows: "3", value: apiToken, placeholder: t('conversation.zoomExport.placeholder'), disabled: inputDisabled, onChange: (e) => updateApiToken(e.target.value), className: classes.invitation, variant: "outlined", inputRef: textFieldRef })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => call(setOff), disabled: sendDisabled, className: classes.connectButton, style: {
                        backgroundColor: theme.palette.ava.blue,
                        opacity: sendDisabled ? 0.7 : 1,
                    } },
                    ' ',
                    t('conversation.zoomExport.connect'),
                    ' ',
                    loading && React.createElement(CircularProgress, { size: 20, className: classes.progress })))),
        React.createElement(Dialog, { open: on && showDuplicateTokenWarning, keepMounted: true, onClose: () => ({}), className: classes.tokenWarningDialog, PaperProps: {
                style: {
                    padding: '12px 24px',
                },
            } },
            React.createElement(DialogContent, null,
                React.createElement("img", { src: ZoomDuplicatedTokenImage, alt: "Sad face", className: classes.tokenWarningImage }),
                React.createElement(Typography, { variant: "h4", className: classes.tokenWarningTitle, align: "center", gutterBottom: true }, t('conversation.zoomExportDuplicateWarning.title')),
                React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('conversation.zoomExportDuplicateWarning.body1')),
                React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('conversation.zoomExportDuplicateWarning.body2'))),
            React.createElement(DialogActions, { className: classes.tokenWarningDialogActions },
                React.createElement(Button, { onClick: () => broadcastToZoom(setOff), className: classes.tokenWarningButton },
                    "\u00A0",
                    !loading && t('conversation.zoomExportDuplicateWarning.confirm'),
                    "\u00A0",
                    loading && React.createElement(CircularProgress, { size: 20, color: "secondary", className: classes.progress }))))));
}
