/* eslint-disable no-shadow */
import { withStyles, withTheme } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MicOff from '../../../assets/images/micOff.svg';
import MicOn from '../../../assets/images/micOn.svg';
import VolumeDown from '../../../assets/images/volumeDown.svg';
import VolumeMute from '../../../assets/images/volumeMute.svg';
import VolumeUp from '../../../assets/images/volumeUp.svg';
import { useNotifications } from '../../hooks/useNotifications';
import { selectAudioV2Status, selectIncludeMicrophoneWithInternalAudio, selectSelectedMicrophone, } from '../../selectors/audioV2';
import { selectCaptionQuality } from '../../selectors/conversation';
import { maybeRestartRecording, RecordingStatus, setIncludeMicrophoneWithInternalAudio, startRecording, } from '../../store/slices/audioV2';
import { addNotification } from '../../store/slices/ccMode';
import { useAppDispatch, useAppSelector } from '../../store/store';
const styles = (theme) => ({
    root: {
        width: '150px',
    },
    volumeImage: {
        width: 19,
    },
    button: {
        border: 'none',
        margin: 0,
        outline: 'none',
        padding: 3,
        background: 'rgba(0, 0, 0, 0.5)',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: theme.palette.ava.grey5,
        },
    },
    buttonLeft: {
        borderBottomLeftRadius: 5,
        borderTopLeftRadius: 5,
    },
    buttonRight: {
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    controllers: {
        position: 'relative',
        zIndex: 100,
        WebkitAppRegion: 'no-drag',
        display: 'flex',
        background: theme.palette.ava.grey5,
        borderRadius: 5,
        '& > :not(:last-child)': {
            marginRight: 1,
        },
    },
    volumeDisplay: {
        borderRadius: 5,
        position: 'relative',
        left: 12,
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 4,
        transition: 'opacity 300ms',
        opacity: 0,
    },
    volume: {
        position: 'relative',
        top: 2,
        left: -4,
        backgroundColor: '#18191A',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        border: '1px solid #18191A',
    },
    volumeRectangle: {
        flexGrow: 0,
        flexShrink: 0,
        backgroundColor: 'white',
        height: 8,
        width: 12,
        marginRight: 1,
    },
    volumeOffRectangle: {
        flexGrow: 0,
        flexShrink: 0,
        backgroundColor: '#18191A',
        height: 8,
        width: 12,
        marginRight: 1,
    },
});
const ScribeVolumeController = (props) => {
    var _a;
    const { addNotification, className, classes, electronCaptionMode, status, t, theme } = props;
    const { notify } = useNotifications();
    const lastVolumeTouchAt = useRef(0);
    const [volume, setVolume] = useState(0);
    const [showVolume, setShowVolume] = useState(false);
    const selectedCaptions = useAppSelector(selectCaptionQuality);
    const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
    const audioV2Status = useAppSelector(selectAudioV2Status);
    const includeMicrophoneWithInternalAudio = useAppSelector(selectIncludeMicrophoneWithInternalAudio);
    const dispatch = useAppDispatch();
    useEffect(() => {
        window.electronIPC.invokeGetVolume().then((volume) => {
            setVolume(parseInt(parseFloat(volume), 10));
        });
    }, [audioV2Status]);
    const setVolumeFun = (volume) => {
        let newVolume = volume;
        if (newVolume > 100)
            newVolume = 100;
        if (newVolume < 0)
            newVolume = 0;
        setVolume(newVolume);
        setShowVolume(true);
        window.electronIPC.sendChangeVolume(newVolume);
        lastVolumeTouchAt.current = new Date().getTime();
        setTimeout(() => {
            if (new Date().getTime() - lastVolumeTouchAt.current >= 2000) {
                setShowVolume(false);
            }
        }, 2000);
    };
    const handleMicOnOf = () => {
        dispatch(setIncludeMicrophoneWithInternalAudio(!includeMicrophoneWithInternalAudio));
        if (audioV2Status === RecordingStatus.RECORDING) {
            dispatch(maybeRestartRecording());
            if (includeMicrophoneWithInternalAudio) {
                dispatch(setIncludeMicrophoneWithInternalAudio(false));
                notify(t('microphone.soundIsNotGettingCaptioned'));
            }
        }
        else {
            dispatch(startRecording());
        }
    };
    const volumeRectangles = [];
    for (let i = 0; i < 10; i += 1) {
        if (i < volume / 10) {
            volumeRectangles.push(React.createElement("div", { key: i, className: classes.volumeRectangle }));
        }
        else {
            volumeRectangles.push(React.createElement("div", { key: i, className: classes.volumeOffRectangle }));
        }
    }
    const controllerStyle = {};
    if (!electronCaptionMode) {
        controllerStyle.position = 'absolute';
        controllerStyle.left = 10;
        controllerStyle.top = 25;
    }
    const volumeStyle = {
        opacity: showVolume ? 1 : 0,
    };
    if (!electronCaptionMode) {
        volumeStyle.position = 'absolute';
        volumeStyle.left = 127;
        volumeStyle.top = 23;
    }
    let background = theme.palette.ava.grey;
    if (audioV2Status === RecordingStatus.RECORDING) {
        if (selectedCaptions === undefined) {
            if (((_a = status === null || status === void 0 ? void 0 : status.scribe) === null || _a === void 0 ? void 0 : _a.status) === 'ongoing') {
                background = theme.palette.ava.green;
            }
            else {
                background = theme.palette.ava.gradientBlue;
            }
        }
        else if (selectedCaptions === 'premium') {
            background = theme.palette.ava.gradientBlue;
        }
        else {
            background = theme.palette.ava.blue;
        }
        if (!includeMicrophoneWithInternalAudio) {
            background = theme.palette.ava.red2;
        }
    }
    else {
        background = theme.palette.ava.grey;
    }
    return (React.createElement("div", { className: className },
        electronCaptionMode && (React.createElement("div", { className: classes.volumeDisplay, style: volumeStyle },
            React.createElement("div", { className: classes.volume }, volumeRectangles))),
        React.createElement("div", { className: classes.controllers, style: controllerStyle },
            (selectedMicrophone === null || selectedMicrophone === void 0 ? void 0 : selectedMicrophone.isInternal) && (React.createElement("button", { type: "button", className: `${classes.button} ${classes.buttonLeft}`, onClick: handleMicOnOf, style: { background } },
                React.createElement("img", { src: includeMicrophoneWithInternalAudio && audioV2Status === RecordingStatus.RECORDING ? MicOn : MicOff, alt: "mute mic", className: classes.volumeImage }))),
            React.createElement("button", { type: "button", className: classNames(classes.button, {
                    [classes.buttonLeft]: !(selectedMicrophone === null || selectedMicrophone === void 0 ? void 0 : selectedMicrophone.isInternal),
                }), onClick: () => setVolumeFun(0) },
                React.createElement("img", { src: VolumeMute, alt: "volume mute", className: classes.volumeImage })),
            React.createElement("button", { type: "button", className: classes.button, onClick: () => setVolumeFun(volume - 10) },
                React.createElement("img", { src: VolumeDown, alt: "volume down", className: classes.volumeImage })),
            React.createElement("button", { type: "button", className: `${classes.button} ${classes.buttonRight}`, onClick: () => setVolumeFun(volume + 10) },
                React.createElement("img", { src: VolumeUp, alt: "volume up", className: classes.volumeImage }))),
        !electronCaptionMode && (React.createElement("div", { className: classes.volumeDisplay, style: volumeStyle },
            React.createElement("div", { className: classes.volume }, volumeRectangles)))));
};
const mapStateToProps = ({ scribeConversation: { status } }) => ({
    status,
});
const mapDispatchToProps = {
    addNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTheme(withTranslation()(ScribeVolumeController))));
