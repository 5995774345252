import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Hidden } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectIsHost } from '../../selectors/legacy-conversation';
import { setConnectToMeetingsOpen } from '../../store/slices/uiState';
import { useAppSelector } from '../../store/store';
import { useAppDispatch } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    tooltipPlacementBottom: {
        margin: '20px 0',
    },
    connectButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 44,
        minWidth: 'max-content',
        minHeight: 44,
        marginLeft: 4,
        marginRight: 4,
        marginTop: -4,
        padding: 10,
        backgroundColor: '#263252',
        border: 'none',
        outline: 'none',
        borderRadius: 12,
        boxSizing: 'border-box',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: theme.palette.ava.grey5,
            cursor: 'pointer',
        },
    },
    connectButtonClicked: {
        backgroundColor: theme.palette.ava.deepBlue,
    },
    icon: {
        height: 64,
        marginLeft: 2,
    },
}));
const ConnectToMeetingsButton = ({ isActive }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isHost = useAppSelector(selectIsHost);
    const handleClick = () => {
        dispatch(setConnectToMeetingsOpen(!isActive));
    };
    return (React.createElement(Tooltip, { classes: {
            tooltipPlacementBottom: classes.tooltipPlacementBottom,
        }, title: !isHost ? t('connectToOnlineMeetings.onlyHost') : t('connectToOnlineMeetings.title'), placement: "bottom", disableInteractive: true },
        React.createElement("button", { className: classnames(classes.connectButton, {
                [classes.connectButtonClicked]: isActive,
            }), onClick: handleClick },
            React.createElement(VideocamOutlinedIcon, { className: classes.icon }),
            React.createElement(Hidden, { smDown: true },
                React.createElement("span", null, t('connectToOnlineMeetings.title'))))));
};
export default ConnectToMeetingsButton;
