import { Fab, Hidden } from '@mui/material';
import { withTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMeetIcon from '../../components/Icons/GoogleMeetIcon';
import MicrosoftTeamsIcon from '../../components/Icons/MicrosoftTeamsIcon';
import VideocamOutlined from '../../components/Icons/VideoCamOutlined';
import ZoomIcon from '../../components/Icons/ZoomIcon';
import useRecallAIManager from '../../hooks/useRecallAIManager';
import { selectIsHost } from '../../selectors/legacy-conversation';
import { selectRecallAIService, selectRecallAIStatus } from '../../selectors/recallAI';
import { setConnectToMeetingsOpen } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    fab: {
        backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'transparent',
        textTransform: 'none',
        fontSize: 18,
        color: '#102867',
        fontWeight: 600,
        height: 44,
        flexShrink: 0,
        marginRight: 5,
        border: '1px solid #E3E5E9',
        boxShadow: 'none',
        transition: 'all 300ms ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.ava.navy5,
            color: '#0679B4',
        },
    },
    text: { marginLeft: 10 },
}));
function ConnectToMeetingsButton({ theme, hideText, open, tabletOrMobile }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const classes = useStyles(theme);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const service = useAppSelector(selectRecallAIService);
    const isHost = useAppSelector(selectIsHost);
    const recallAIManager = useRecallAIManager();
    const getServiceIcon = () => {
        if (service === 'Zoom')
            return React.createElement(ZoomIcon, null);
        else if (service === 'Google Meet')
            return React.createElement(GoogleMeetIcon, null);
        else
            return React.createElement(MicrosoftTeamsIcon, null);
    };
    const handleClick = () => {
        if (recallAIStatus === 'CAPTIONING' && tabletOrMobile) {
            recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.mobileClick();
        }
        dispatch(setConnectToMeetingsOpen(!open));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Fab, { variant: "extended", className: classes.fab, onClick: handleClick, disabled: !isHost },
            recallAIStatus === 'CAPTIONING' && tabletOrMobile ? getServiceIcon() : React.createElement(VideocamOutlined, null),
            !hideText && (React.createElement(Hidden, { only: ['xs', 'sm'] },
                React.createElement("span", { className: classes.text }, t('connectToOnlineMeetings.title')))))));
}
export default withTheme(ConnectToMeetingsButton);
