import { createSelector } from 'reselect';
import { selectAvaId } from './userProfile';
const selectScribeConversation = (state) => state.scribeConversation;
export const selectStatus = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.status);
// This is similar to status.participants, but:
// * It is a map, not an array
// * It can contain virtual participants (e.g. 'recall-ai')
// * It is enhanced with solo-dia information in the reducer
export const selectSpeakersMap = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.speakers);
// This is similar to speakers, but:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is exactly as returned by the backend (duplicate participants?)
export const selectParticipants = createSelector([selectScribeConversation], (scribeConversation) => { var _a; return ((_a = scribeConversation.status) === null || _a === void 0 ? void 0 : _a.participants) || []; });
// This is the best of both worlds for selectParticipants and selectSpeakers:
// * It is an array
// * It contains only real participants (no virtual participants)
// * It is enhanced with solo-dia information in the reducer
// * It does not contain duplicates
export const selectUniqueParticipants = createSelector([selectParticipants, selectSpeakersMap], (participants, speakers) => [...new Set(participants.map((p) => p.avaId))].map((avaId) => speakers[avaId]));
export const selectTranscripts = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcripts);
export const selectTranscriptsCurrent = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcriptsCurrent);
export const selectTranscriptsFinal = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.transcriptsFinal);
export const selectLang = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.lang);
export const selectSpeechLang = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.speechLang);
export const selectAllTranscripts = createSelector([selectTranscripts, selectTranscriptsCurrent, selectTranscriptsFinal], (transcripts, transcriptsCurrent, transcriptsFinal) => transcriptsFinal.concat(transcriptsCurrent).map((transcriptId) => transcripts[transcriptId]));
export const selectFurthestObservedCursors = createSelector([selectScribeConversation], (scribeConversation) => scribeConversation.furthestObservedCursors);
export const selectIsHost = createSelector([selectStatus, selectAvaId], (status, avaId) => {
    // we check status.host.avaName for DevExp purposes
    // since when changing to other servers (eg PENTEST)
    // the first status.host.avaId is a default host value
    // that's unrelated to the actual host or any participants
    if (status && status.host && status.host.avaId && status.host.avaName && avaId) {
        return avaId === status.host.avaId;
    }
    else
        return false;
});
