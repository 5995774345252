import Close from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback } from 'react';
import { selectElectronCaptionMode } from '../selectors/ui';
import { addNotification } from '../store/slices/ccMode';
import { useAppDispatch, useAppSelector } from '../store/store';
export const useNotifications = () => {
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const dispatch = useAppDispatch();
    const notify = useCallback((text, options, button) => {
        if (electronCaptionMode) {
            dispatch(addNotification({
                text,
                timestamp: Date.now(),
                type: (options === null || options === void 0 ? void 0 : options.variant) || 'default',
                link: button === null || button === void 0 ? void 0 : button.url,
                linkText: button === null || button === void 0 ? void 0 : button.text,
            }));
        }
        else {
            return enqueueSnackbar(text, Object.assign(Object.assign({}, (options || {})), { action: (key) => (React.createElement(Fragment, null,
                    button && (React.createElement(Button, { href: button.url, target: "_blank", rel: "noreferrer noopener", variant: "outlined", style: {
                            borderColor: (options === null || options === void 0 ? void 0 : options.color) || 'white',
                            borderRadius: 20,
                            color: (options === null || options === void 0 ? void 0 : options.color) || 'white',
                            fontSize: '0.9em',
                            textTransform: 'none',
                        } }, button.text)),
                    React.createElement(Button, { onClick: () => {
                            closeSnackbar(key);
                        }, style: {
                            color: 'inherit',
                        } },
                        React.createElement(Close, null)))) }));
        }
    }, [closeSnackbar, enqueueSnackbar, electronCaptionMode]);
    return {
        notify,
    };
};
