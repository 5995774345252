import { connect } from 'react-redux';
import { clearScribeConversationState, getTranscriptStats, scribeCreateTranscript, scribeDeselectTranscriptWord, } from '../../../actions';
import { setCCMode } from '../../../store/slices/ccMode';
import { setConversationStatus } from '../../../store/slices/rateConversation';
import ConversationPage from './ConversationPage';
const mapStateToProps = ({ scribeDashboard: { scribeTrainingRequested }, scribeConversation: { speechLang, status, transcriptsCurrent, transcriptsFinal }, }) => ({
    haveTranscripts: transcriptsCurrent.length + transcriptsFinal.length > 0,
    scribeTrainingRequested,
    speechLang,
    status,
});
const mapDispatchToProps = {
    clearScribeConversationState,
    getTranscriptStats,
    scribeCreateTranscript,
    scribeDeselectTranscriptWord,
    setCCMode,
    setConversationStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConversationPage);
